import Axios from "axios";
import configs from "constants/config";
import { router } from "index";
import Cookies from "js-cookie";
import { throttle } from "lodash";

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

const getMyTimezone = () => {
  const offset = new Date().getTimezoneOffset();
  let sign = "+";
  if (offset > 0) {
    sign = "-";
  }
  let hour = Math.abs(offset) / 60;
  let hourText = hour >= 10 ? `${hour}` : `0${hour}`;
  return `${sign}${hourText}:00`;
};
axiosInstance.defaults.headers.common["X-Timezone"] = getMyTimezone();
axiosInstance.defaults.headers.common["Accept-Language"] = "ja";

export async function getRefreshToken() {
  try {
    const res = await Axios({
      method: "POST",
      url: `${configs.API_DOMAIN}/operation/auth/refresh-token`,
      headers: {
        Authorization: `Bearer ${Cookies.get("refreshToken")}`,
      },
    });
    if (res.status === 200) {
      const data = res.data;
      const { token, expiresAt } = data?.data?.accessToken;
      const { token: refreshToken, expiresAt: refreshTokenExpiresAt } =
        data?.data?.refreshToken;
      Cookies.set("token", token, { expires: new Date(expiresAt * 1000) });
      Cookies.set("refreshToken", refreshToken, {
        expires: new Date(refreshTokenExpiresAt * 1000),
      });
    } else {
      Cookies.remove("token");
      Cookies.remove("refreshToken");
    }
  } catch (error: any) {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
  }
}

const throttled = throttle(getRefreshToken, 10000, { trailing: false });

axiosInstance.interceptors.request.use(
  async (config: any) => {
    if (!!Cookies.get("token")) {
      config.headers.Authorization = `Bearer ${Cookies.get("token")}`;
    } else if (!!Cookies.get("refreshToken")) {
      await throttled();
      if (!!Cookies.get("token"))
        config.headers.Authorization = `Bearer ${Cookies.get("token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const logout = () => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  router.navigate("/login");
};
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (error?.response?.status !== 401) {
      return Promise.reject(error);
    }
    const refreshToken = Cookies.get("refreshToken");
    if (!refreshToken) {
      return logout();
    }
    await throttled();
    if (!!Cookies.get("token")) {
      originalConfig.headers.Authorization = `Bearer ${Cookies.get("token")}`;
      return axiosInstance(originalConfig);
    }
    logout();
  }
);

export const sendGet = (url: string, params?: any) =>
  axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance
    .post(url, params, { params: queryParams })
    .then((res) => res.data);
export const sendPut = (url: string, params?: any) =>
  axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) =>
  axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) =>
  axiosInstance.delete(url, { params }).then((res) => res.data);
