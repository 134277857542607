import { sendGet } from "./axios";

export const getListIndustry = () =>
  sendGet("/employer/industry/group/dropdown");

export const getListPrefectures = () =>
  sendGet("/employer/prefecture/dropdown");

export const getListDistrict = (id: number) =>
  sendGet(`/employer/district/dropdown??prefectureId=${id}`);

export const getListMunicipalities = (id: number) =>
  sendGet(`/employer/municipality/dropdown?prefectureId=${id}`);

export const getListStaffInChargesApi = () =>
  sendGet("/operation/account/staff/dropdown?roleType=sale");
