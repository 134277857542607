import styles from "./styles.module.scss";
import { useRef, useState } from "react";
import {
  Cluster,
  ClusterStats,
  MarkerClusterer,
} from "@googlemaps/markerclusterer";
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_MAPS_API_KEY } from "constants/fakeData";
import { getBusinessStatisticApi } from "api/dataAnalysis";
import { useQuery } from "react-query";
import { QueryKey } from "utils/enum";
import { handleErrorMessage } from "utils/helper";
import Loading from "pages/Loading/Loading";
import { useTranslation } from "react-i18next";

export default function StoreDistribution() {
  const JP_CENTER_LOCATION = {
    lat: 37.61418935097123,
    lng: 139.52371817217804,
  };
  const { t } = useTranslation();
  const [isMapLoading, setIsMapLoading] = useState(true);

  const mapRef = useRef(null);

  const loader = new Loader({
    apiKey: `${process.env.REACT_APP_MAP_KEY}`,
  });

  const render = (
    { count, position }: Cluster,
    stats: ClusterStats
  ): google.maps.Marker => {
    // change color if this cluster has more markers than the mean cluster
    const color = "#ff0000";

    // create svg url with fill color
    const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
  </svg>`);

    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  };

  const { isFetching } = useQuery(
    [QueryKey.STATISTIC_BUSINESS_LOCATION],
    () => getBusinessStatisticApi(),
    {
      onError: handleErrorMessage,
      onSuccess: (data) => {
        const dataWithoutNull = data.filter(
          (location: any) => !!location.lat && !!location.lng
        );
        const map = new window.google.maps.Map(mapRef.current as any, {
          center: JP_CENTER_LOCATION,
          zoom: 6,
        });
        const markers = dataWithoutNull.map((location: any) => {
          return new window.google.maps.Marker({
            position: {
              lat: parseFloat(location.lat),
              lng: parseFloat(location.lng),
            },
          });
        });
        new MarkerClusterer({
          map,
          markers,
          renderer: {
            render: render,
          },
        });
      },
      enabled: !isMapLoading,
    }
  );

  loader.load().then(() => {
    setIsMapLoading(false);
  });

  return (
    <div className={styles.container}>
      {isFetching && <Loading />}
      <div className={styles.header}>
        <div className={styles.contTitle}>
          <div className={styles.title}>{t("nav.storeDistribution")}</div>
        </div>
      </div>
      <div style={{ height: 20 }} />
      <div ref={mapRef} style={{ height: "70vh", width: "100%" }} />
    </div>
  );
}
