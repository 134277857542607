import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { getDetailContact, replyContact } from "api/contact";
import { ArrowLeft } from "assets/icon";
import CustomButton from "components/CustomButton";
import LoadingComponent from "components/LoadingComponent";
import TinyMce from "components/TinyMce";
import { isTypeVideoFile } from "utils/const";
import { QueryKey, ReportType } from "utils/enum";
import { dateUtils, getTimeTextPost, handleErrorMessage } from "utils/helper";
import styles from "./styles.module.scss";
import { IContact } from "utils/interface";
import { Input } from "antd";
import { countForSideBar } from "utils/queryKey";
import { getDetailReport } from "api/report";
import dayjs from "dayjs";
import Relative from "dayjs/plugin/relativeTime";

dayjs.extend(Relative);

export default function ReportDetail() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { id } = useParams();

  const { data: reportDetail, isLoading: loadingBugDetail } = useQuery({
    queryKey: [QueryKey.REPORT_DETAIL],
    queryFn: () => getDetailReport(id || ""),
    enabled: Boolean(id),
    onSuccess: (res) => {},
    onError: (error) => handleErrorMessage(error),
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.back}>
          <ArrowLeft
            onClick={() => {
              navigate(-1);
            }}
          />
          <span>{t("headerPage.reportDetail")}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.information}>
          <div className={styles.information__item}>
            <div className={styles.information__label}>
              {t("table.nameStore")}:
            </div>
            <div className={styles.information__value}>
              {reportDetail?.data?.business?.name}
            </div>
          </div>
          <div className={styles.information__item}>
            <div className={styles.information__label}>
              {t("table.jobInformation")}:
            </div>
            <div className={styles.information__value}>
              {`${dateUtils.getMonthDate(
                reportDetail?.data?.recruitmentPost?.startAt
              )} ${getTimeTextPost(
                reportDetail?.data?.recruitmentPost?.startAt,
                reportDetail?.data?.recruitmentPost?.businessHours,
                reportDetail?.data?.recruitmentPost?.isLastLabelShown
              )}`}
            </div>
          </div>

          <div className={styles.information__item}>
            <div className={styles.information__label}>
              {t("table.userName")}:
            </div>
            <div className={styles.information__value}>
              {reportDetail?.data?.candidateProfile?.nickname}
            </div>
          </div>

          <div className={styles.information__item}>
            <div className={styles.information__label}>
              {t("table.option")}:
            </div>
            <div className={styles.information__value}>
              {reportDetail?.data?.type === ReportType.ABSENT
                ? t("reportText.reportOption1")
                : t("reportText.reportOption2")}
            </div>
          </div>

          <div className={styles.information__item}>
            <div className={styles.information__label}>
              {t("table.noteReport")}:
            </div>
            <div className={styles.information__value}>
              {reportDetail?.data?.note}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
