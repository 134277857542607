import { sendDelete, sendGet, sendPatch, sendPost } from "./axios";

export const getFAQList = (type: string, params?: any) =>
  sendGet(`/operation/faq/${type}`, params);

export const createFAQ = (params?: any) => sendPost("/operation/faq", params);

export const editFAQ = (type: string, id: number, params: any) =>
  sendPatch(`/operation/faq/${type}/${id}`, params);

export const deleteFAQ = (id: number) => sendDelete(`/operation/faq/${id}`);
