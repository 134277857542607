import { Form, Input, message } from "antd";
import {
  addNumberPosts,
  getCountPost,
  getListSettingsByTypeApi,
  patchSettingsApi,
} from "api/masterData";
import CustomButton from "components/CustomButton";
import LabelInput from "components/LabelInput";
import Loading from "pages/Loading/Loading";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { EGeneralSettings } from "utils/enum";
import { handleErrorMessage } from "utils/helper";
import { commonValidate } from "utils/ruleForms";
import styles from "./styles.module.scss";

function AddPosts() {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  useQuery({
    queryKey: "CountPost",
    queryFn: () => getCountPost(),
    onSuccess: (data: any) => {
      form.setFieldsValue({ value: data?.value });
    },
  });

  const handleSubmit = async (payload: any) => {
    try {
      const param = {
        value: Number(payload.value),
      };
      await addNumberPosts(param);
      message.success(t("detailStoreText.addSuccess"));
    } catch (err: any) {
      handleErrorMessage(err);
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <Form onFinish={handleSubmit} form={form}>
          <div className={styles.header}>
            <div className={styles.contTitle}>
              <div className={styles.title}>{t("nav.addPost")}</div>
              <CustomButton
                title={t("common.save")}
                className={styles.addBtn}
                onClick={form.submit}
              />
            </div>
          </div>
          <div style={{ height: 20 }} />
          <div className={styles.content}>
            <div className={styles.subTitle}>{t("nav.addPost")}</div>
            <div style={{ height: 20 }} />
            <div className={styles.row}>
              <div className="f-1 mr-20">
                <LabelInput title={t("detailStoreText.number")} isRequired />
                <Form.Item
                  name={"value"}
                  validateFirst
                  rules={[commonValidate.required, commonValidate.whiteSpace]}
                >
                  <Input
                    className="inputCustom"
                    placeholder={t("detailStoreText.number")}
                    maxLength={25}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddPosts;
