export const errorText = {
  largeFileDanger:
    "*画像の容量が過ぎました。容量5MBまでの画像をアップロードしてください。",
  wrongFormatFileDanger:
    "*Only images/videos formatted in (.png, .jpg, .jpeg, .mov, .mp4) are allowed。",
  manyFilesDanger: "*Only 10 images/videos are allowed。",
};

export const commonText = {
  next: "次へ",
  return: "戻る",
  save: "保存する",
  filter: "絞り込む",
  no: "いいえ",
  yes: "はい",
  newSort: "新着",
  crop: "切り取る",
};

export const routeNameText = {
  home: "HOME",
  favorite: "お気にいり",
  schedule: "スケジュール",
  chat: "チャット",
  myPage: "マイページ",
};

export const recruitment = {
  applied: "応募済",
  stopApply: "応募をやめる",
  cancel: "キャンセル",
  toReview: "レビューする",
  confirm: "確認",
  questionStopApplying: "本当に応募をやめますか？",
  penaltyP: "ペナルティP",
  descriptionPenalty:
    "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. \nExercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.\nAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.\nVelit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.\nAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. \nExercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.\nAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.\nVelit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
  review: "レビュー",
  titleReview: "ーレビューして、ペナルティPを減らそう!!",
  levelOfSatisfaction: "満足度",
  good: "良かった",
  notGood: "残念だった",
  tooltipTextReview: "特に良かったと思う項目を選択(複数選択可)",
  bodyOfReview: "レビュー本文",
  placeholderBodyReview:
    "・良かったことなどを記入しましょう。\n※誹謗中傷・個人情報に関わることなどは記入しないでください。記入した場合は、編集または削除の上、強制退会措置を取らせていただく場合があります。",
  modalBadWordTitle: "「投稿できません」",
  modalBadWordSubTitle: "禁止ワードが含まれているため投稿できません。",
  modalBadWordNotice: "ゃべ開生、クム泉古、訪ネテニ",
  tag: "タグ",
};

export const componentsText = {
  importImageVideos: "動画・画像アップロード",
  imagesVideosSelection: "ファイルをアップロード",
  limitFilesCount: "写真・動画を最大10枚まで登録可能",
  videosDocumentsSelection: "ファイルをアップロード",
};

export const storeText = {
  modalTitle: "店舗を削除",
};

export const confirmText = {
  openConfirmStore: "本当に承認しますか？",
  deleteStore: "本当に拒否しますか？",
};
export const guideText = {
  step1:
    "仕事が完了しました。レビュータブに遷移する為、こちらにタップしてください。",
  step2: "こちらにタップし、レビューを書きましょう。",
};

export const dataAnalysisText = {
  date: "日付",
  statisticRecruitmentPostViews: "投稿閲覧数",
  statisticBusinessViews: "店舗詳細閲覧数",
  statisticPostsFavorites: "お気にいり数",
  statisticAppliedCandidates: "応募数",
  statisticHiredCandidates: "採用数",
  modalAppliedCandidate: "応募者",
  modalAmount: "人数",
  modalHour: "時間帯",
  tooltipBusinessFavorites: "ユーザーに投稿をお気にいりにされた数",
  previousMonth: "先月比",
  thisMonth: "今月",
  user: "ユーザー",
  viewPostInDay: "投稿別のアクセス数",
  viewAllInDay: "ふ～ぷ全体のアクセス数",
  goToPostDetail: "投稿の詳細画面へ",
  wholeSystem: "ふ～ぷ全体アクセス数",
};
