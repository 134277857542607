import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { Detail } from "components/ActionTable/ActionTable";
import dayjs from "dayjs";
import Relative from "dayjs/plugin/relativeTime";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { linkStorePage, linkUserPage } from "utils/const";

import { ContactSite, ContactStatus, ReportType } from "utils/enum";
import { dateUtils, getIndexTable, getTimeTextPost } from "utils/helper";
import { IContact, IFilter } from "utils/interface";
import styles from "./styles.module.scss";

interface IProps {
  data: Array<any>;
  filter: IFilter;
  loading: boolean;
  onDelete?(id: number): void;
}

dayjs.extend(Relative);

export default function ReportTable({
  data,
  filter,
  loading,
  onDelete,
}: IProps) {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
      width: 80,
    },
    {
      title: t("table.nameStore"),
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => (
        <Link
          to={`/store-account/store-list/detail/${record?.business?.id}`}
          target="_blank"
          className={classNames("text-link", "line-1")}
        >
          {record?.business?.name}
        </Link>
      ),
      width: 200,
    },
    {
      title: t("table.jobInformation"),
      dataIndex: "storeName",
      key: "storeName",
      render: (value, record, index) => (
        <span className="">
          {`${dateUtils.getMonthDate(
            record?.recruitmentPost?.startAt
          )} ${getTimeTextPost(
            record?.recruitmentPost?.startAt,
            record?.recruitmentPost?.businessHours,
            record?.recruitmentPost?.isLastLabelShown
          )}`}
        </span>
      ),
    },
    {
      title: t("table.userName"),
      dataIndex: "workDate",
      key: "workDate",
      render: (value, record, index) => (
        <Link
          to={`${linkUserPage}${record?.candidateProfile?.accountId}`}
          target="_blank"
          className={classNames("text-link", "line-1")}
        >
          {record?.candidateProfile?.nickname}
        </Link>
      ),
    },
    {
      title: t("table.option"),
      dataIndex: "status",
      key: "status",
      render: (value, record, index) => (
        <span className="">
          {record?.type === ReportType.ABSENT
            ? t("reportText.reportOption1")
            : t("reportText.reportOption2")}
        </span>
      ),
    },
    {
      title: t("table.noteReport"),
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => (
        <span className="line-1">{record?.note}</span>
      ),
      width: 300,
    },
    {
      title: t("table.viewDetails"),
      key: "action",
      width: 150,
      render: (value, record, index) => (
        <div className={styles.center}>
          <Detail
            onClick={() => {
              navigate(`/report/detail/${record?.id}`);
            }}
          />
        </div>
      ),
      align: "center",
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.id}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
      />
    </>
  );
}
