import {
  getListIndustry,
  getListPrefectures,
  getListStaffInChargesApi,
} from "api/resources";
import { useAuth } from "hooks/useAuth";
import AgeAnalysis from "pages/DataAnalysis/AgeAnalysis";
import TreatmentSearch from "pages/DataAnalysis/TreatmentSearch";
import StoreDistribution from "pages/DataAnalysis/StoreDistribution";
import RoleAction from "pages/Staff/RoleAction";
import UserEdit from "pages/User/UserEdit";
import { lazy } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "wrappers/ProtectedRoute";
import FAQManage from "pages/FAQ";
import { usePermissions, useRole } from "hooks/usePermissions";
import { RoleType } from "utils/enum";
import AddPosts from "pages/MasterData/AddPosts";
import ScrollToTop from "components/ScrollToTop";
import ReportManagement from "pages/Report/ReportManage";
import ReportDetail from "pages/Report/ReportDetail";
import DetailPopup from "pages/MasterData/PopupManager/DetailPopup";
import EditPopup from "pages/MasterData/PopupManager/EditPopup";
import ExpUser from "pages/User/Exp";
import ApplicationHistory from "pages/ApplicationHistory";

const Dashboard = lazy(() => import("pages/Dashboard"));
const Login = lazy(() => import("pages/Login"));
const StaffList = lazy(() => import("pages/Staff/StaffList"));
const RoleManage = lazy(() => import("pages/Staff/RoleManage"));
const StoreList = lazy(() => import("pages/StoreAccount/StoreList"));
const AccountBrowsing = lazy(
  () => import("pages/StoreAccount/AccountBrowsing")
);
const DetailStoreBrowsing = lazy(
  () => import("pages/StoreAccount/DetailStoreBrowsing")
);
const DetailResponseCampaign = lazy(
  () => import("pages/Campaign/DetailResponseCampaign")
);
const EditStore = lazy(() => import("pages/StoreAccount/EditStore"));
const DetailStore = lazy(() => import("pages/StoreAccount/DetailStore"));
const PostManage = lazy(() => import("pages/Post/PostManage"));
const PostDetail = lazy(() => import("pages/Post/PostDetail"));
const PostAction = lazy(() => import("pages/Post/PostAction"));
const UserManage = lazy(() => import("pages/User/UserManage"));
const UserDetail = lazy(() => import("pages/User/UserDetail"));
const CreatedNoti = lazy(() => import("pages/Notification/CreatedNoti"));
const NotiContent = lazy(() => import("pages/Notification/NotiContent"));
const PlanList = lazy(() => import("pages/Plan/PlanList"));
const PlanDetail = lazy(() => import("pages/Plan/PlanDetail"));
const RequestPlan = lazy(() => import("pages/Plan/RequestPlan"));
const CupSize = lazy(() => import("pages/MasterData/CupSize"));
const ExperienceGroup = lazy(
  () => import("pages/MasterData/Experience/ExperienceGroup")
);
const Experience = lazy(() => import("pages/MasterData/Experience/Experience"));
const PickUpRequest = lazy(() => import("pages/MasterData/PickUpRequest"));
const Panel = lazy(() => import("pages/MasterData/Panel"));
const AreaLevel1 = lazy(() => import("pages/MasterData/AreaList/AreaLevel1"));
const AreaLevel2 = lazy(() => import("pages/MasterData/AreaList/AreaLevel2"));
const AreaLevel3 = lazy(() => import("pages/MasterData/AreaList/AreaLevel3"));
const IndustryLevel1 = lazy(
  () => import("pages/MasterData/Industry/IndustryLevel1")
);
const IndustryLevel2 = lazy(
  () => import("pages/MasterData/Industry/IndustryLevel2")
);
const ReviewTag = lazy(() => import("pages/MasterData/ReviewTag"));
const BugManagement = lazy(() => import("pages/Bug/BugManage"));
const BugDetail = lazy(() => import("pages/Bug/BugDetail"));
const ContactManagement = lazy(() => import("pages/Contact/ContactManage"));
const ContactDetail = lazy(() => import("pages/Contact/ContactDetail"));

const ApplicationDeadline = lazy(
  () => import("pages/MasterData/ApplicationDeadline")
);
const PopupManager = lazy(() => import("pages/MasterData/PopupManager"));
const WorkingHours = lazy(() => import("pages/MasterData/WorkingHours"));
const Treatment = lazy(() => import("pages/MasterData/Treatment"));
const QuestionnaireManager = lazy(
  () => import("pages/MasterData/QuestionnaireManager")
);
const CheckboxManagement = lazy(() => import("pages/Settings/Checkbox"));
const FormManagement = lazy(() => import("pages/Settings/Form"));
const TooltipManagement = lazy(() => import("pages/Settings/Tooltip"));
const OtherManagement = lazy(() => import("pages/Settings/Other"));
const FavoriteStores = lazy(() => import("pages/DataAnalysis/FavoriteStores"));
const IndustryAnalysis = lazy(
  () => import("pages/DataAnalysis/IndustryAnalysis")
);
const IndustryStatisticAnalysis = lazy(
  () => import("pages/DataAnalysis/Industry")
);

const MyPage = lazy(() => import("pages/MyPage"));
const CampaignList = lazy(() => import("pages/Campaign/CampaignList"));
const ResponsesCampaign = lazy(
  () => import("pages/Campaign/ResponsesCampaign")
);

export default function AppRoute() {
  const authContext = useAuth();
  const role = useRole();
  const isAdmin = role?.type === RoleType.ADMIN || !!role?.isSuperAdmin;
  useQuery({
    queryKey: "industries",
    queryFn: () => getListIndustry(),
    keepPreviousData: true,
  });
  useQuery({
    queryKey: "prefectures",
    queryFn: () => getListPrefectures(),
    keepPreviousData: true,
  });
  useQuery({
    queryKey: "staffInCharges",
    queryFn: () => getListStaffInChargesApi(),
    keepPreviousData: true,
    enabled: !!authContext?.token,
  });

  return (
    <ScrollToTop>
      <Routes>
        <Route
          path="/login"
          element={authContext?.token ? <Navigate to="/" replace /> : <Login />}
        />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/staff/staff-list" element={<StaffList />} />
          <Route path="/staff/role-manage" element={<RoleManage />} />
          <Route path="/staff/role-manage/add" element={<RoleAction />} />
          <Route path="/staff/role-manage/edit/:id" element={<RoleAction />} />
          <Route path="/users" element={<UserManage />} />
          <Route path="/users/detail/:id" element={<UserDetail />} />
          <Route path="/application-history" element={<ApplicationHistory />} />
          {/* <Route path="revenue" element={<Staff />} /> */}
          <Route path="/posts" element={<PostManage />} />
          <Route path="/posts/detail/:id" element={<PostDetail />} />
          <Route path="/posts/edit/:id" element={<PostAction />} />
          <Route path="/noti/created-noti" element={<NotiContent />} />
          <Route path="/noti/noti-content" element={<CreatedNoti />} />
          <Route path="/plans/plan-list" element={<PlanList />} />
          <Route path="/plans/plan-list/detail/:id" element={<PlanDetail />} />
          <Route path="/plans/request-plan" element={<RequestPlan />} />
          <Route path="/bugs-report" element={<BugManagement />} />
          <Route path="/bug/detail/:id" element={<BugDetail />} />
          <Route path="/contacts" element={<ContactManagement />} />
          <Route path="/report" element={<ReportManagement />} />
          <Route path="/report/detail/:id" element={<ReportDetail />} />
          <Route path="/contact/detail/:id" element={<ContactDetail />} />
          <Route path="/store-account/store-list" element={<StoreList />} />
          <Route
            path="/store-account/store-list/detail/:id"
            element={<DetailStore />}
          />
          <Route
            path="/store-account/store-list/add-store"
            element={<EditStore />}
          />
          <Route
            path="/store-account/store-list/edit-store/:id"
            element={<EditStore />}
          />
          <Route
            path="/store-account/account-browsing"
            element={<AccountBrowsing />}
          />
          <Route
            path="/store-account/account-browsing/detail/:id"
            element={<DetailStoreBrowsing />}
          />
          <Route
            path="/store-account/account-browsing/confirm-store/:id"
            element={<EditStore />}
          />
          <Route path="/master-data/cup-size" element={<CupSize />} />
          <Route path="/master-data/experience" element={<ExperienceGroup />} />
          <Route path="/master-data/experience/:id" element={<Experience />} />
          <Route
            path="/master-data/pickup-request"
            element={<PickUpRequest />}
          />
          <Route path="/master-data/panel" element={<Panel />} />
          <Route path="/master-data/area-level-1" element={<AreaLevel1 />} />
          <Route
            path="/master-data/area-level-2/:id"
            element={<AreaLevel2 />}
          />
          <Route
            path="/master-data/area-level-3/:id"
            element={<AreaLevel3 />}
          />
          <Route
            path="/master-data/industry-level-1"
            element={<IndustryLevel1 />}
          />
          <Route
            path="/master-data/industry-level-2/:id"
            element={<IndustryLevel2 />}
          />
          <Route path="/master-data/review-tag" element={<ReviewTag />} />
          <Route
            path="/master-data/application-deadline"
            element={<ApplicationDeadline />}
          />
          <Route path="/master-data/working-hours" element={<WorkingHours />} />
          <Route path="/master-data/treatment" element={<Treatment />} />
          <Route
            path="/master-data/questionnaire-manager"
            element={<QuestionnaireManager />}
          />
          {/* <Route path="plans" element={<Staff />} /> */}
          {/* <Route path="" element={<Navigate to="/dashboard" replace />} /> */}
          <Route
            path="/plans/checkbox-management"
            element={<CheckboxManagement />}
          />
          <Route path="/plans/form-management" element={<FormManagement />} />
          <Route path="/users/add-user" element={<UserEdit />} />
          <Route path="/users/edit-user/:id" element={<UserEdit />} />
          <Route
            path="/plans/tooltips-management"
            element={<TooltipManagement />}
          />
          <Route
            path="/plans/others-management"
            element={<OtherManagement />}
          />
          <Route path="/campaigns/campaign-list" element={<CampaignList />} />
          <Route
            path="/campaigns/responses-campaign"
            element={<ResponsesCampaign />}
          />
          <Route
            path="/campaigns/responses-campaign/detail/:id"
            element={<DetailResponseCampaign />}
          />
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/data-analysis/store-distribution"
              element={<StoreDistribution />}
            />
            <Route path="/data-analysis/age" element={<AgeAnalysis />} />
            <Route
              path="/data-analysis/treatment-search"
              element={<TreatmentSearch />}
            />
            <Route
              path="/data-analysis/favorite-stores"
              element={<FavoriteStores />}
            />
            <Route path="/faq" element={<FAQManage />} />
            <Route
              path="/data-analysis/industry"
              element={<IndustryAnalysis />}
            />
            <Route
              path="/data-analysis/industry-statistic"
              element={<IndustryStatisticAnalysis />}
            />
          </>

          <Route path="/mypage" element={<MyPage />} />
          <Route path="/master-data/add-posts" element={<AddPosts />} />
          <Route path="/master-data/popup-manager" element={<PopupManager />} />
          <Route
            path="/master-data/popup-detail/:id"
            element={<DetailPopup />}
          />
          <Route path="/master-data/popup-edit/:id" element={<EditPopup />} />
          <Route path="/master-data/popup-edit" element={<EditPopup />} />
          <Route path="/users/exp/:id" element={<ExpUser />} />
        </Route>
      </Routes>
    </ScrollToTop>
  );
}
