import {
  IFilter,
  IIndustryAnalytic,
  IResponse,
  IResponseList,
  IRevenueStatistic,
  ISearchFavoriteStores,
  ISurvey,
} from "utils/interface";

import { sendGet, sendPatch } from "./axios";

interface IGetTreatmentHistoryStatistic {
  startAt: string | null;
  endAt: string | null;
  page: number;
  limit: number;
}

interface IGetIndustry {
  page?: number;
  limit?: number;
}

interface IGetStatisticRevenueDashboard {
  startAt?: any;
  endAt?: any;
  mode: "month" | "date";
}

interface IGetSurveyStatistic {
  startAt: string | null;
  endAt: string | null;
}

export const getRangeAgeStatisticApi = (params: {
  startAt?: string;
  endAt?: string;
}): Promise<
  Array<{
    target: string;
    totals: number;
  }>
> =>
  sendGet(`/operation/account/range-age/statistic`, params).then(
    (res) => res.data
  );

export const getBusinessStatisticApi = (): Promise<any> =>
  sendGet(`/operation/business/location/statistic`).then((res) => res.data);

export const getTreatmentHistoryStatistic = (
  payload: IGetTreatmentHistoryStatistic
) => sendGet(`/operation/treatment/history/statistic`, payload);

export const getStatisticRevenueDashboard = (
  params: IGetStatisticRevenueDashboard
): Promise<IResponse<{ items: IRevenueStatistic[]; amount: number }>> =>
  sendGet(`/operation/plan/revenue/statistic`, params);

export const getSurveyStatistic = (
  params: IGetSurveyStatistic
): Promise<IResponse<{ items: ISurvey[] }>> =>
  sendGet(`/operation/survey/answer/statistic`, params);

export const getFavoriteStoresStatistic = (
  params: IFilter
): Promise<{ items: ISearchFavoriteStores[]; totalItems: number }> =>
  sendGet(`/operation/business/statistic`, params).then((res) => res.data);

export const getIndustryStatistic = (
  params: IGetIndustry
): Promise<IResponseList<IIndustryAnalytic>> =>
  sendGet(`/operation/industry/recruitment-post/statistic`, params);
interface IParamsUpdateStatisticIndustry {
  industryId: number;
  municipalityId: number;
  quantity: number;
}

export const getStatisticIndustry = (params: any) =>
  sendGet("/operation/industry/statistic", params);

export const updateActualQuantity = (params: IParamsUpdateStatisticIndustry) =>
  sendPatch(`/operation/industry/actual-quantity`, params);

interface IResponseDailyPost {
  data: {
    items: {
      id: number;
      title: string;
      totalItems: number;
      createdAt: string;
      startAt: string;
      businessHours: number;
      dates: { totalItems: number; date: string; id: number }[];
      wholeSystem: {
        dates: {
          date: string;
          id: number;
          value: number;
        }[];
      };
    }[];
  };
}

export const getStatisticDailyPost = (params: {
  startAt: string;
  endAt: string;
  businessId: number;
}): Promise<IResponseDailyPost> =>
  sendGet("/operation/recruitment-post/daily-view/statistic", params);

interface IResponseHourlyPost {
  data: {
    items: {
      totalItems: number;
      hour: number;
    }[];
  };
}

export const getStatisticHourlyPost = (
  id: number,
  params: {
    startAt: string;
    endAt: string;
  }
): Promise<IResponseHourlyPost> =>
  sendGet(`/operation/recruitment-post/${id}/hourly-view/statistic`, params);

interface IResponseHourlyAll {
  data: {
    items: {
      percentage: number;
      hour: number;
    }[];
  };
}

export const getStatisticHourlyAll = (params: {
  startAt: string;
  endAt: string;
}): Promise<IResponseHourlyAll> =>
  sendGet(
    `/operation/recruitment-post/whole-system/hourly-view/statistic`,
    params
  );
