import {
  EGeneralSettings,
  EQuestionSurvey,
  TMasterDataStatus,
  WebType,
} from "utils/enum";
import { sendGet, sendPatch, sendPost } from "./axios";

/**
 * Cup size
 *   */
export const getListCupSizeApi = (): Promise<
  Array<{
    id: number;
    createdAt: string;
    updatedAt: string;
    value: string;
    status: TMasterDataStatus;
  }>
> => sendGet(`/operation/cup-size`).then((res) => res.data);
export const createCupSizeApi = ({
  value,
  status,
}: {
  value: string;
  status: TMasterDataStatus;
}): Promise<any> => sendPost(`/operation/cup-size`, { value, status });
export const patchCupSizeApi = ({
  id,
  status,
}: {
  id: number;
  status: TMasterDataStatus;
}): Promise<any> => sendPatch(`/operation/cup-size/${id}`, { status });

/**
 * Experience
 *   */
export const getListExperienceGroupApi = (): Promise<
  Array<{
    id: number;
    createdAt: string;
    value: string;
    status: TMasterDataStatus;
  }>
> => sendGet(`/operation/experience/group`).then((res) => res.data.items);
export const getListExperienceByGroupIdApi = ({
  groupId,
}: {
  groupId: number;
}): Promise<
  Array<{
    id: number;
    createdAt: string;
    value: string;
    status: TMasterDataStatus;
  }>
> => sendGet(`/operation/experience/${groupId}`).then((res) => res.data.items);
export const patchExperienceGroupApi = ({
  id,
  status,
}: {
  id: number;
  status: TMasterDataStatus;
}): Promise<any> => sendPatch(`/operation/experience/group/${id}`, { status });
export const patchExperienceApi = ({
  id,
  status,
}: {
  id: number;
  status: TMasterDataStatus;
}): Promise<any> => sendPatch(`/operation/experience/${id}`, { status });
export const createExperienceGroupApi = ({
  value,
  status,
}: {
  value: string;
  status: TMasterDataStatus;
}): Promise<any> => sendPost(`/operation/experience/group`, { value, status });
export const createExperienceApi = ({
  value,
  experienceGroupId,
  status,
}: {
  value: string;
  experienceGroupId: number;
  status: TMasterDataStatus;
}): Promise<any> =>
  sendPost(`/operation/experience`, { value, experienceGroupId, status });
export const getDetailsExperienceGroupApi = ({
  groupId,
}: {
  groupId: number;
}): Promise<{
  id: number;
  createdAt: string;
  updatedAt: string;
  value: string;
  status: TMasterDataStatus;
}> => sendGet(`/operation/experience/group/${groupId}`).then((res) => res.data);

/**
 * Pick up request
 *   */
export const getListPickupRequestApi = (): Promise<
  Array<{
    id: number;
    createdAt: string;
    value: string;
    status: TMasterDataStatus;
  }>
> => sendGet(`/operation/pickup-request`).then((res) => res.data.items);
export const createPickupRequestApi = ({
  value,
  status,
}: {
  value: string;
  status: TMasterDataStatus;
}): Promise<any> => sendPost(`/operation/pickup-request`, { value, status });
export const patchPickupRequestApi = ({
  id,
  status,
}: {
  id: number;
  status: TMasterDataStatus;
}): Promise<any> => sendPatch(`/operation/pickup-request/${id}`, { status });

/**
 * Prefecture (Area level 1)
 *   */
export const getListPrefectureApi = (params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
  }>;
  totalItems: number;
}> => sendGet(`/operation/prefecture`, params).then((res) => res.data);
export const patchPrefectureStatusApi = ({
  id,
}: {
  id: number;
}): Promise<any> => sendPatch(`/operation/prefecture/${id}/status`);
export const createPrefectureApi = ({
  value,
  status,
}: {
  value: string;
  status: TMasterDataStatus;
}): Promise<any> => sendPost(`/operation/prefecture`, { value, status });
export const getDetailsPrefectureByIdApi = (
  id?: number
): Promise<{
  id: number;
  value: string;
  status: TMasterDataStatus;
}> => sendGet(`/operation/prefecture/${id}`).then((res) => res.data);

/**
 * District (Area level 2)
 *   */
export const getListDistrictsApi = (params: {
  prefectureId: number;
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
    prefectureId: number;
  }>;
  totalItems: number;
}> => sendGet(`/operation/district`, params).then((res) => res.data);
export const patchDistrictStatusApi = ({ id }: { id: number }): Promise<any> =>
  sendPatch(`/operation/district/${id}/status`);
export const createDistrictApi = ({
  value,
  prefectureId,
  status,
}: {
  value: string;
  prefectureId: number;
  status: TMasterDataStatus;
}): Promise<any> =>
  sendPost(`/operation/district`, { value, prefectureId, status });
export const getDetailsDistrictByIdApi = (
  id?: number
): Promise<{
  id: number;
  value: string;
  status: TMasterDataStatus;
}> => sendGet(`/operation/district/${id}`).then((res) => res.data);

/**
 * Municipalities (Area level 3)
 *   */
export const getListMunicipalitiesApi = (params: {
  districtId: number;
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
    districtId: number;
  }>;
  totalItems: number;
}> => sendGet(`/operation/municipality`, params).then((res) => res.data);
export const patchMunicipalityStatusApi = ({
  id,
}: {
  id: number;
}): Promise<any> => sendPatch(`/operation/municipality/${id}/status`);
export const createMunicipalityApi = ({
  value,
  districtId,
  status,
}: {
  value: string;
  districtId: number;
  status: TMasterDataStatus;
}): Promise<any> =>
  sendPost(`/operation/municipality`, { value, districtId, status });

/**
 * Industry (Level 1)
 *   */
export const getListIndustryGroupsApi = (params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
  }>;
  totalItems: number;
}> => sendGet(`/operation/industry/group`, params).then((res) => res.data);
export const patchIndustryGroupStatusApi = ({
  id,
}: {
  id: number;
}): Promise<any> => sendPatch(`/operation/industry/group/${id}/status`);
export const createIndustryGroupApi = ({
  value,
  status,
}: {
  value: string;
  status: TMasterDataStatus;
}): Promise<any> => sendPost(`/operation/industry/group`, { value, status });
export const getDetailsIndustryGroupByIdApi = (
  id?: number
): Promise<{
  id: number;
  value: string;
  status: TMasterDataStatus;
}> => sendGet(`/operation/industry/group/${id}`).then((res) => res.data);

/**
 * Industry (Level 2)
 *   */
export const getListIndustriesApi = (params: {
  page?: number;
  limit?: number;
  industryGroupId: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
    industryGroupId: number;
  }>;
  totalItems: number;
}> => sendGet(`/operation/industry`, params).then((res) => res.data);
export const patchIndustryStatusApi = ({ id }: { id: number }): Promise<any> =>
  sendPatch(`/operation/industry/${id}/status`);
export const createIndustryApi = ({
  value,
  status,
  industryGroupId,
}: {
  value: string;
  status: TMasterDataStatus;
  industryGroupId: number;
}): Promise<any> =>
  sendPost(`/operation/industry`, { value, status, industryGroupId });

/**
 * Panel
 *   */
export const getListPanelsApi = (params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
    industryGroupId: number;
  }>;
  totalItems: number;
}> => sendGet(`/operation/panel`, params).then((res) => res.data);
export const patchPanelStatusApi = ({ id }: { id: number }): Promise<any> =>
  sendPatch(`/operation/panel/${id}/status`);
export const createPanelApi = ({
  value,
  status,
}: {
  value: string;
  status: TMasterDataStatus;
}): Promise<any> => sendPost(`/operation/panel`, { value, status });

/**
 * Application deadline
 *   */
export const getListApplicationDeadlinesApi = (params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
  }>;
  totalItems: number;
}> =>
  sendGet(`/operation/application-deadline`, params).then((res) => res.data);
export const patchApplicationDeadlineStatusApi = ({
  id,
}: {
  id: number;
}): Promise<any> => sendPatch(`/operation/application-deadline/${id}/status`);
export const createApplicationDeadlineApi = ({
  value,
  status,
}: {
  value: number;
  status: TMasterDataStatus;
}): Promise<any> =>
  sendPost(`/operation/application-deadline`, { value, status });

/**
 * Treatment
 *   */
export const getListTreatmentsApi = (params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<{
    id: number;
    value: string;
    status: TMasterDataStatus;
    iconPath: number;
    iconUrl: number;
  }>;
  totalItems: number;
}> => sendGet(`/operation/treatment`, params).then((res) => res.data);
export const patchTreatmentStatusApi = ({ id }: { id: number }): Promise<any> =>
  sendPatch(`/operation/treatment/${id}/status`);
export const createTreatmentApi = ({
  value,
  status,
  iconPath,
}: {
  value: string;
  status: TMasterDataStatus;
  iconPath: string;
}): Promise<any> =>
  sendPost(`/operation/treatment`, { value, status, iconPath });

/**
 * Review Tags
 *   */
export const getListReviewTagsByTypeApi = (params: {
  page?: number;
  limit?: number;
  type?: WebType;
}): Promise<{
  items: Array<{
    id: number;
    name: string;
    status: TMasterDataStatus;
    iconPath: number;
    createdAt: string;
  }>;
  totalItems: number;
}> =>
  sendGet(`/operation/tag/${params?.type}`, {
    page: params?.page,
    limit: params?.limit,
  }).then((res) => res.data);
export const patchReviewTagStatusApi = ({
  id,
  type,
  status,
}: {
  id: number;
  type: WebType;
  status: TMasterDataStatus;
}): Promise<any> => sendPatch(`/operation/tag/${type}/${id}`, { status });
export const createTagByTypeApi = ({
  name,
  status,
  type,
}: {
  name: string;
  status: TMasterDataStatus;
  type: WebType;
}): Promise<any> => sendPost(`/operation/tag`, { name, status, type });

/**
 * General settings (Business hours / Banners / Radius / Blog)
 *   */
export const getListSettingsByTypeApi = (params: {
  type?: EGeneralSettings;
}): Promise<{
  id: number;
  createdAt: string;
  updatedAt: string;
  field: EGeneralSettings;
  minimum: number;
  maximum: number;
  fileIds?: Array<any>;
  url?: string;
  status?: string;
}> =>
  sendGet(`/operation/general-setting/${params?.type}`).then((res) => res.data);
export const patchSettingsApi = (
  type: EGeneralSettings,
  body: {
    minimum?: number;
    maximum?: number;
    files?: {
      path: string;
      type: string;
      size: number;
    }[];
    url?: string;
    status?: string;
  }
): Promise<any> => sendPatch(`/operation/general-setting/${type}`, body);

export interface ISurvey {
  id: number;
  content: string;
  type: string;
  status: TMasterDataStatus;
  answers: Array<{
    id: number;
    content: string;
  }>;
}

/**
 * Survey questions
 *   */
export const getListSurveysApi = (params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: Array<ISurvey>;
  totalItems: number;
}> => sendGet(`/operation/survey`, params).then((res) => res.data);
export const patchSurveyStatusApi = ({
  id,
  status,
}: {
  id: number;
  status: TMasterDataStatus;
}): Promise<any> => sendPatch(`/operation/survey/${id}`, { status });
export const createSurveyApi = ({
  question,
  answers,
}: {
  question: {
    content: string;
    type: EQuestionSurvey;
  };
  answers: {
    content: string;
  }[];
}): Promise<any> => sendPost(`/operation/survey`, { question, answers });

export const addNumberPosts = (params: any): Promise<any> =>
  sendPatch("/operation/general-setting/additional_post_count", params);

export const getCountPost = (): Promise<any> =>
  sendGet(`/operation/general-setting/additional_post_count`).then(
    (res) => res.data
  );

export const updateStatusAddPost = (id: number): Promise<any> =>
  sendPatch(`/operation/business/${id}/donation-post-count-status`);

export const getListPrefecture = (): Promise<any> =>
  sendGet(`/operation/prefecture/dropdown`).then((res) => res.data);

export const getIDCard = (): Promise<any> =>
  sendGet(`/operation/pickup-request/ID-card`).then((res) => res.data);
