import { Checkbox, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { getDetailPermission, updateRolePermission } from "api/staff";
import { ArrowLeft } from "assets/icon";
import classNames from "classnames";
import CustomButton from "components/CustomButton";
import CustomNotification from "components/CustomNotification";
import LabelInput from "components/LabelInput";
import { useMutation, useQuery } from "react-query";
import { ELocalStorageKey, FeatureManagement, Roles } from "utils/enum";
import {
  handleErrorMessage,
  rolesPermission,
  rolesText,
  roleTextMap,
} from "utils/helper";
import { detailRoleKey } from "utils/queryKey";
import RoleItem from "./RoleItem";
import styles from "./styles.module.scss";

export default function RoleAction() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { data } = useQuery({
    queryKey: detailRoleKey,
    queryFn: () => getDetailPermission(Number(id)),
    enabled: !!id,
    onSuccess(data) {
      let dataFormat: any = {};
      data.data?.forEach((item: any) => {
        dataFormat[item?.feature] = item.abilities;
      });
      form.setFieldsValue({
        ...dataFormat,
        name: roleTextMap[data?.data[0]?.role as Roles],
      });
    },
  });

  const handleSubmit = async (payload: any) => {
    try {
      const { name, ...data } = payload;
      const dataFormat: any = Object.keys(data)?.map((item: any) => {
        return { feature: item, permissions: data[item] || [] };
      });
      await updateRolePermission({ id: Number(id), data: dataFormat });
      CustomNotification({
        type: "success",
        message: t("notification.success"),
      });
      navigate("/staff/role-manage");
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const mutate = useMutation({ mutationFn: handleSubmit });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.back}>
          <ArrowLeft
            onClick={() => {
              navigate(-1);
            }}
          />
          <span>
            {!!id
              ? t("headerPage.updateDecentralization")
              : t("headerPage.addDecentralization")}
          </span>
        </div>
        <div className={styles.actions}>
          <CustomButton
            title={t("button.save")}
            className={styles.saveBtn}
            onClick={() => form.submit()}
          />
        </div>
      </div>
      <div className={styles.content}>
        <Form form={form} onFinish={mutate.mutateAsync}>
          <div className={styles.row}>
            <div className={classNames("f-1", styles.roleName)}>
              <LabelInput title={t("roleActionText.roleName")} />
              <Form.Item name="name">
                <Input
                  className="inputCustom"
                  placeholder={t("placeholder.role")}
                  disabled={!!id}
                />
              </Form.Item>
            </div>
          </div>
          {Object.entries(FeatureManagement).map(([key, value]) => {
            if (
              value === FeatureManagement.MASTER_DATA &&
              localStorage.getItem(ELocalStorageKey.IS_SUPER_ADMIN) !== "true"
            ) {
              return null;
            }
            return (
              <RoleItem key={value} title={rolesText[value]} name={value}>
                {rolesPermission[value]?.map((item: any) => (
                  <Checkbox
                    key={`${value}-${item.title}`}
                    value={item.value}
                    style={{ lineHeight: "32px" }}
                  >
                    {item.title}
                  </Checkbox>
                ))}
              </RoleItem>
            );
          })}
        </Form>
      </div>
    </div>
  );
}
