import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { getIndexTable } from "utils/helper";
import { IFilter, ISearchTreatment } from "utils/interface";

interface IProps {
  data: ISearchTreatment[];
  filter: IFilter;
  loading: boolean;
  onDelete?(id: number): void;
}

export default function TreatmentSearchTable({
  data,
  filter,
  loading,
  onDelete,
}: IProps) {
  const { t } = useTranslation();

  const columns: ColumnsType<ISearchTreatment> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
    },
    {
      title: t("table.treatment"),
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => (
        <span className="">{record?.value}</span>
      ),
    },
    {
      title: t("table.totalNumberSearch"),
      dataIndex: "storeName",
      key: "storeName",
      render: (value, record, index) => (
        <span className="">{record?.totalItem}</span>
      ),
      align: "center",
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.treatmentId}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
      />
    </>
  );
}
