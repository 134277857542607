import {
  IconCrownDiamond,
  IconCrownGold,
  IconCrownPrincess,
  IconCrownSilver,
  IconCrownWhite,
} from "assets/images";
import { CandidateRankingConfig } from "utils/enum";
import { IFilter } from "utils/interface";

export const defaultFilterValue: IFilter = {
  page: 1,
  limit: 10,
};

export const OPTION_OTHER = -1;

export const dataRanking = {
  [CandidateRankingConfig.NONE]: {
    rank: "ランクなし",
    icon: "",
    color: "#f971A5",
    min: 0,
    max: 1,
    upLevel: 1,
  },
  [CandidateRankingConfig.WHITE]: {
    rank: "WHITE",
    icon: IconCrownWhite,
    color: "#a7dfeb",
    min: 0,
    max: 299,
    upLevel: 300,
  },
  [CandidateRankingConfig.SILVER]: {
    rank: "SILVER",
    icon: IconCrownSilver,
    color: "#b2b3b4",
    min: 300,
    max: 599,
    upLevel: 600,
  },
  [CandidateRankingConfig.GOLD]: {
    rank: "GOLD",
    icon: IconCrownGold,
    color: "#e8c31e",
    min: 600,
    max: 1199,
    upLevel: 1200,
  },
  [CandidateRankingConfig.DIAMOND]: {
    rank: "DIAMOND",
    icon: IconCrownDiamond,
    color: "#6fb0ff",
    min: 1200,
    max: 2399,
    upLevel: 2400,
  },
  [CandidateRankingConfig.PRINCESS]: {
    rank: "PRINCESS",
    icon: IconCrownPrincess,
    color: "#ff89c6",
    min: 2400,
    max: 2401,
    upLevel: 2401,
  },
};
