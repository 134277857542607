import { sendGet } from "./axios";

interface IGetListReport {
  page?: number;
  limit?: number;
}

export const getListReport = (params: IGetListReport): Promise<any> =>
  sendGet(`/operation/report`, params);

export const getDetailReport = (reportId: string): Promise<any> =>
  sendGet(`/operation/report/detail/${reportId}`);
