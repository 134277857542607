import closeModalIcon from "./images/close-modal-icon.svg";
import avatarDefaultIcon from "./images/avatar-default-icon.svg";
import arrowBottomIcon from "./images/arrow-bottom.svg";
import uploadIcon from "./images/upload-icon.svg";
import clockIcon from "./images/clock-icon.svg";
import avatarDefault from "./images/avatar-default.svg";
import profileEx1 from "./images/profile-ex1.svg";
import profileEx2 from "./images/profile-ex2.svg";
import huChan from "./images/hu-chan.png";
import profileEx3 from "./images/profile-ex3.svg";
import editIcon from "./images/edit-white.svg";
import deleteIcon from "./images/delete-white.svg";
import happyIcon from "./images/happy-icon.svg";
import disappointIcon from "./images/cry-icon.svg";
import addIcon from "./images/add.svg";
import heartIcon from "./images/heart-icon.svg";
import calendarIcon from "./images/calendar-icon.svg";
import clockGrayIcon from "./images/clock-gray-icon.svg";
import dollarCircleIcon from "./images/dollar-circle-icon.svg";
import privateRoomWaiting from "./images/private-room-waiting.svg";
import shortTimeOk from "./images/short-time-ok.svg";
import paymentTransportExpense from "./images/payment-transport-expense.svg";
import noTraining from "./images/no-training.svg";
import tattooScratches from "./images/tattoo-scratches.svg";
import inexperiencedPersonWelcome from "./images/inexperienced-person-welcome.svg";
import repeatWelcome from "./images/repeat-welcome.svg";
import shuttleServiceAvailable from "./images/shuttle-service-available.svg";
import dotIcon from "./images/dot-icon.svg";
import notiIcon from "./images/noti-icon.svg";
import avatarNotiDefault from "./images/avatar-noti-default.svg";
import removeAvatar from "./images/remove-avatar.svg";

export const images = {
  huChan,
  closeModalIcon,
  avatarDefaultIcon,
  arrowBottomIcon,
  uploadIcon,
  clockIcon,
  avatarDefault,
  profileEx1,
  profileEx2,
  profileEx3,
  editIcon,
  deleteIcon,
  happyIcon,
  disappointIcon,
  addIcon,
  heartIcon,
  calendarIcon,
  clockGrayIcon,
  dollarCircleIcon,
  privateRoomWaiting,
  shortTimeOk,
  paymentTransportExpense,
  noTraining,
  tattooScratches,
  inexperiencedPersonWelcome,
  repeatWelcome,
  shuttleServiceAvailable,
  dotIcon,
  notiIcon,
  avatarNotiDefault,
  removeAvatar,
};
