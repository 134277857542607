import React from "react";
import ItemExp from "./ItemExp";
import styles from "./styles.module.scss";
import dayjs from "dayjs";

interface IProp {
  data: any;
}

export default function GroupExp({ data }: IProp) {
  return (
    <div className={styles.wrapContainer}>
      <p className={styles.wrapTitle}>{`${data?.title}年`}</p>
      {data?.list?.map((item: any, indexItem: number) => {
        return (
          <div className={styles.wrapListDate} key={indexItem}>
            {item?.list?.map((itemDate: any, index: number) => {
              return (
                <ItemExp
                  key={index}
                  date={dayjs(item?.title).format("MM/DD")}
                  content={itemDate?.name}
                  exp={Number(itemDate?.actionChangeValue)}
                  idStore={itemDate?.idStore}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
