import i18next from "i18n/i18n";
import {
  acceptedAvatarType,
  acceptedImageType,
  LIMIT_AVATAR_SIZE,
  LIMIT_IMAGE_SIZE,
  MIN_LENGTH_PASSWORD,
} from "./const";
import { convertFileTypeFromUrl } from "./helper";
import {
  REGEX_CREDENTIAL,
  REGEX_EMAIL,
  REGEX_EMOJI,
  REGEX_JAPANESE,
  REGEX_NOT_JAPANESE,
  REGEX_NOT_JAPANESE_SPECIAL,
  REGEX_PASSWORD,
  REGEX_PHONE,
  REGEX_TWITTER,
  REGEX_URL,
} from "./regex";
import dayjs from "dayjs";

export const RuleForm = {
  password: () => [
    {
      required: true,
      message: i18next.t("validate.passwordRequired"),
    },
    () => ({
      validator(_: any, value: any) {
        const stringValue = value?.toString();
        const stringLength = stringValue?.length;
        if (stringLength === 0) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordRequired"))
          );
        }
        if (!stringValue?.trim()) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordNoAllSpaces"))
          );
        }
        if (
          stringValue.match(REGEX_EMOJI) ||
          stringValue.match(REGEX_JAPANESE)
        ) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordWrongFormat"))
          );
        }
        if (stringLength < MIN_LENGTH_PASSWORD) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordTooShort"))
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  newPassword: () => [
    { required: true, message: i18next.t("validate.passwordRequired") },
    () => ({
      validator(_: any, value: any) {
        const stringValue = value?.toString();
        const stringLength = stringValue?.length;
        if (stringLength === 0) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordRequired"))
          );
        }
        if (!stringValue?.trim()) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordNoAllSpaces"))
          );
        }
        if (
          stringValue.match(REGEX_EMOJI) ||
          stringValue.match(REGEX_JAPANESE)
        ) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordWrongFormat"))
          );
        }
        if (stringLength < MIN_LENGTH_PASSWORD) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordTooShort"))
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  confirmPassword: () => [
    { required: true, message: i18next.t("validate.passwordRequired") },
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        if (!value || getFieldValue("new_password") === value) {
          return Promise.resolve();
        }
        const stringValue = value?.toString();
        const stringLength = stringValue?.length;
        if (stringLength === 0) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordRequired"))
          );
        }
        if (!stringValue?.trim()) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordNoAllSpaces"))
          );
        }
        if (
          stringValue.match(REGEX_EMOJI) ||
          stringValue.match(REGEX_JAPANESE)
        ) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordWrongFormat"))
          );
        }
        if (stringLength < MIN_LENGTH_PASSWORD) {
          return Promise.reject(
            new Error(i18next.t("validate.passwordTooShort"))
          );
        }
        return Promise.reject(
          new Error(i18next.t("validate.passwordNotMatch"))
        );
      },
    }),
  ],
  username: () => [
    {
      required: true,
      message: i18next.t("validate.usernameRequired"),
    },
    () => ({
      validator(_: any, value: any) {
        const stringValue = value?.toString();
        const stringLength = stringValue?.length;
        if (stringLength === 0) {
          return Promise.reject(
            new Error(i18next.t("validate.usernameRequired"))
          );
        }
        if (!stringValue?.trim()) {
          return Promise.reject(
            new Error(i18next.t("validate.usernameNoAllSpaces"))
          );
        }
        if (
          stringValue?.[0] === " " ||
          stringValue?.[stringLength - 1] === " "
        ) {
          return Promise.reject(
            new Error(i18next.t("validate.usernameWrongFormat"))
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  avatar: () => [
    () => ({
      validator(_: any, value: any) {
        if (!value) {
          return Promise.reject(
            new Error(i18next.t("validate.avatarNoRequiredDanger"))
          );
        }
        if (
          !acceptedAvatarType?.includes(
            convertFileTypeFromUrl(value?.url) ||
              convertFileTypeFromUrl(value?.name) ||
              ""
          )
        ) {
          return Promise.reject(
            new Error(i18next.t("validate.avatarWrongFormatDanger"))
          );
        }
        if (value?.size > LIMIT_AVATAR_SIZE) {
          return Promise.reject(
            new Error(i18next.t("validate.avatarOversizeDanger"))
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  avatarNotRequire: () => [
    () => ({
      validator(_: any, value: any) {
        if (
          value &&
          !acceptedAvatarType?.includes(
            convertFileTypeFromUrl(value?.url) ||
              convertFileTypeFromUrl(value?.name) ||
              ""
          )
        ) {
          return Promise.reject(
            new Error(i18next.t("validate.avatarWrongFormatDanger"))
          );
        }
        if (value?.size > LIMIT_AVATAR_SIZE) {
          return Promise.reject(
            new Error(i18next.t("validate.avatarOversizeDanger"))
          );
        }
        return Promise.resolve();
      },
    }),
  ],
};

export const commonValidate = {
  email: {
    pattern: REGEX_EMAIL,
    message: i18next.t("validate.emailWrongFormat"),
  },
  emailSecond: {
    pattern: REGEX_EMAIL,
    message: i18next.t("validate.incorrectEmailFormat"),
  },
  phone: {
    pattern: REGEX_PHONE,
    message: i18next.t("validate.phoneIsNotValid"),
  },
  required: {
    required: true,
    message: i18next.t("validate.fieldIsRequired"),
  },
  password: {
    pattern: REGEX_PASSWORD,
    message: i18next.t("validate.passwordWrongFormat"),
  },
  whiteSpace: {
    whitespace: true,
    message: i18next.t("validate.noAllSpaces"),
  },
  credential: {
    pattern: REGEX_CREDENTIAL,
    message: i18next.t("validate.credentialInvalid"),
  },
  url: {
    pattern: REGEX_URL,
    message: i18next.t("validate.incorrectFormat"),
  },
  twitter: {
    pattern: REGEX_TWITTER,
    message: i18next.t("validate.incorrectFormat"),
  },
  notJapanese: {
    pattern: REGEX_NOT_JAPANESE,
    message: i18next.t("validate.credentialInvalid"),
  },
  notJapaneseSpecial: {
    pattern: REGEX_NOT_JAPANESE_SPECIAL,
    message: i18next.t("validate.credentialInvalid"),
  },
  isInteger: () => ({
    validator(_: any, value: any) {
      if (Number.isNaN(Number(value))) {
        return Promise.reject(new Error("Must be a number"));
      }
      return Promise.resolve();
    },
  }),
  imageFile: () => ({
    validator(_: any, value: any) {
      if (typeof value === "string") {
        return Promise.resolve();
      }
      if (!acceptedImageType.includes(value?.type)) {
        return Promise.reject(new Error("File type invalid"));
      }
      if (value?.size > LIMIT_IMAGE_SIZE) {
        return Promise.reject(new Error("File have to lower than 50MB"));
      }
      return Promise.resolve();
    },
  }),
  isOptionMunicipalityOther: () => ({
    validator(_: any, value: any) {
      if (value === -1) {
        return Promise.reject(
          new Error('Must choose options different from "N/A" or "Others"')
        );
      }
      return Promise.resolve();
    },
  }),
  isOptionIndustryOther: (otherId: number) => ({
    validator(_: any, value: any) {
      if (value?.[0] === otherId) {
        return Promise.reject(
          new Error('Must choose options different from "N/A" or "Others"')
        );
      }
      return Promise.resolve();
    },
  }),

  CheckStartAt: (startAt: string | undefined) => ({
    validator(_: any, value: any) {
      if (
        (!!startAt &&
          (dayjs(value)?.isSame(dayjs(startAt), "date") ||
            dayjs(value)?.isAfter(dayjs(startAt), "date"))) ||
        !startAt
      ) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(i18next.t("popup.messageStartAt")));
    },
  }),
};
