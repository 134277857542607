import { Form, Input, Select } from "antd";
import Icon from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { IFilter } from "utils/interface";
import styles from "./styles.module.scss";
import { ArrowBottom } from "assets/icon";
import CustomButton from "components/CustomButton";
import { listOptionStatusApplication } from "utils/const";

interface IProps {
  filter: IFilter;
  handleSearch: any;
  dataSelect?: Array<any>;
  onSearch: (data: any) => void;
}

export default function HistoryFilter({ onSearch }: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={onSearch}>
      <div className={styles.userFilter}>
        <div className={styles.statusFilter}>
          <div className={styles.filterLabel}>{t("button.search")}</div>

          <div className={styles.selectStatus}>
            <Form.Item name="status" noStyle>
              <Select
                className="selectCustom"
                suffixIcon={<Icon component={() => <ArrowBottom />} />}
                allowClear={true}
                options={listOptionStatusApplication}
              />
            </Form.Item>
          </div>
        </div>

        <CustomButton
          title={t("button.search")}
          className={styles.searchBtn}
          onClick={() => form.submit()}
        />
      </div>
    </Form>
  );
}
