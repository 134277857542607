import io from "socket.io-client";
import configs from "utils/configs";

const socket = io(configs.API_DOMAIN || "", {
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});
export default socket;
