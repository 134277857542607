import styles from "./styles.module.scss";

interface IProps {
  title: string;
  isRequired?: boolean;
}

export default function LabelInput({ title, isRequired }: IProps) {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      {isRequired ? <span className={styles.required}>*</span> : null}
    </div>
  );
}
