import { Modal, Button } from "antd";
import classNames from "classnames";
import React, { useEffect, useRef, useState, useCallback } from "react";
import Cropper from "react-easy-crop";

import styles from "./ModalCrop.module.scss";
import { commonText } from "utils/text";

interface IProps {
  open: boolean;
  aspect: "4:3" | "3:4" | "1:1";
  listImages: any[];
  onFinishCrop: (data: any) => void;
  cropShape?: "rect" | "round";
}

function ModalCropImage({
  aspect,
  open,
  listImages,
  onFinishCrop,
  cropShape = "rect",
}: IProps) {
  const getAspect = (aspect: "4:3" | "3:4" | "1:1") => {
    if (aspect === "4:3") {
      return 4 / 3;
    }
    if (aspect === "3:4") {
      return 3 / 4;
    }
    return 1 / 1;
  };
  const [crop, setCrop] = useState<any>({
    x: 0,
    y: 0,
  });
  const [images, setImages] = useState<any>([]);
  const [imgSrc, setImgSrc] = useState<any>([]);
  const [index, setIndex] = useState<any>(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  useEffect(() => {
    if (!open) {
      setImages([]);
      setImgSrc([]);
      setIndex(0);
    }
  }, [open]);
  useEffect(() => {
    listImages?.forEach((item: any, index: number) => {
      if (item.type.includes("image") && item.type !== "image/gif") {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setImgSrc((pre: any) => [
            ...pre,
            { index, file: item, url: reader.result?.toString() || "" },
          ])
        );
        reader.readAsDataURL(item);
      }
    });
  }, [listImages]);

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const handleCrop = async () => {
    const canvas = document.createElement("canvas");
    const image: any = await createImage(imgSrc[index]?.url);
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    const ctx: any = canvas.getContext("2d");

    const pixelRatio = 1;
    canvas.width = croppedAreaPixels.width * pixelRatio;
    canvas.height = croppedAreaPixels.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      croppedAreaPixels.x * scaleX,
      croppedAreaPixels.y * scaleY,
      croppedAreaPixels.width * scaleX,
      croppedAreaPixels.height * scaleY,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );
    canvas.toBlob((blob: any) => {
      let file = new File([blob], imgSrc[index].file.name, {
        type: imgSrc[index].file.type,
      });
      setImages([...images, file]);
    }, imgSrc[index].file.type);
    // Converting to base64
    if (index < imgSrc.length - 1) {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    if (images.length === imgSrc.length) {
      const newList = imgSrc.map((item: any, index: number) => ({
        ...item,
        file: images[index],
      }));
      const newListImages = listImages.map((item: any, index: number) => {
        const file = newList.find((sub: any) => sub.index === index);
        return file ? file.file : item;
      });
      onFinishCrop(newListImages);
    }
  }, [images]);
  return (
    <Modal
      open={open}
      title={commonText.crop}
      centered
      closable={false}
      footer={null}
    >
      <div className={styles.cropContainer}>
        <Cropper
          image={imgSrc[index]?.url}
          crop={crop}
          rotation={0}
          zoom={zoom}
          aspect={getAspect(aspect)}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape={cropShape}
        />
      </div>
      <div className={styles.btn_container}>
        <Button
          type="primary"
          className={classNames("btn", [styles.btn])}
          onClick={handleCrop}
        >
          {commonText.crop}
        </Button>
      </div>
    </Modal>
  );
}

export default ModalCropImage;
