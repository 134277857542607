import { Modal } from "antd";
import { images } from "assets/iconString";
import styles from "./styles.module.scss";

interface IProps {
  open: boolean;
  toggle: () => void;
  title: string;
  content: string;
}

export default function ModalDetailFAQ({
  open,
  toggle,
  title,
  content,
}: IProps) {
  return (
    <Modal
      title={
        <>
          <div>{"詳細"}</div>
          <div onClick={toggle}>
            <img src={images.closeModalIcon} />
          </div>
        </>
      }
      className="modalCustom"
      open={open}
      onCancel={toggle}
      closable={false}
      footer={null}
      maskClosable={false}
      centered
    >
      <div className={styles.wrapTitle}> {title}</div>
      <div className={styles.wrapContent}> {content}</div>
    </Modal>
  );
}
