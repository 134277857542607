import Cookies from "js-cookie";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ELocalStorageKey, RoleType } from "utils/enum";
import { useDispatchPermission } from "./usePermissions";

interface ILogin {
  token: string;
  refreshToken: string;
  expiresAtToken: Date;
  expiresAtRefreshToken: Date;
  username?: string;
  profile?: {
    id: number;
    accountId: number;
    roleId: number;
    name?: string;
    role: {
      id: number;
      isSuperAdmin: boolean;
      name: string;
      type: "manager" | "sale";
    };
  };
}
interface AuthContextInterface {
  token: string | undefined;
  refreshToken: string | undefined;
  login: (data: ILogin) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

interface IAuthProvider {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProvider) => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const navigate = useNavigate();
  const dispatchPermission = useDispatchPermission();
  const queryClient = useQueryClient();

  const login = async (payload: ILogin) => {
    Cookies.set("token", payload.token, {
      expires: payload.expiresAtToken,
    });
    Cookies.set("refreshToken", payload.refreshToken, {
      expires: payload.expiresAtRefreshToken,
    });
    if (payload?.username) {
      localStorage.setItem(ELocalStorageKey.USERNAME, `${payload?.username}`);
    }
    if (payload?.profile?.role?.type) {
      localStorage.setItem(
        ELocalStorageKey.ROLE_TYPE,
        `${payload?.profile?.role?.type}`
      );
      localStorage.setItem(
        ELocalStorageKey.IS_SUPER_ADMIN,
        `${payload?.profile?.role?.isSuperAdmin}`
      );
      localStorage.setItem(
        ELocalStorageKey.ROLE_NAME,
        `${payload?.profile?.role?.name}`
      );
      localStorage.setItem(
        ELocalStorageKey.ROLE_ACCOUNT_ID,
        `${payload?.profile?.accountId}`
      );
    }
    navigate("/");
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    queryClient.clear();

    localStorage.removeItem(ELocalStorageKey.IS_SUPER_ADMIN);
    dispatchPermission({
      type: "update",
      data: { permissions: {}, role: {} },
    });

    navigate("/login", { replace: true });
  };

  const value: AuthContextInterface = useMemo(
    () => ({
      token,
      refreshToken,
      login,
      logout,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
