import { Menu, Dropdown, Button, Row } from "antd";
import i18next from "i18next";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { CaretDownOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";
import { useAuth } from "hooks/useAuth";
import { LogoApp } from "assets/images";
import { ELocalStorageKey, RoleType } from "utils/enum";
import { useRole } from "hooks/usePermissions";

interface IProps {
  isPageLogin?: boolean;
}

export default memo(function Header({ isPageLogin = false }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authContext = useAuth();
  const role = useRole();
  const isAdmin = role?.type === RoleType.ADMIN || !!role?.isSuperAdmin;

  const listLanguages = [
    { code: "vi", name: "Vietnamese" },
    { code: "ja", name: "Japanese" },
  ];

  const handleChangeLanguage = (code: string) => {
    i18next.changeLanguage(code);
  };

  const renderSelectLanguage = () => {
    const listMenuLanguage = listLanguages.map(
      (item: { code: string; name: string }) => (
        <Menu.Item
          key={item.code}
          onClick={() => handleChangeLanguage(item.code)}
        >
          {item.name}
        </Menu.Item>
      )
    );
    return <Menu>{listMenuLanguage}</Menu>;
  };

  const menu = (
    <Menu>
      {!isAdmin && (
        <Menu.Item
          key="1"
          onClick={() => {
            navigate("/mypage");
          }}
        >
          {t("common.profile")}
        </Menu.Item>
      )}

      <Menu.Item
        key="2"
        onClick={() => authContext?.logout()}
        className={styles.logout}
      >
        {t("common.logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.header}>
      <div className={styles.contLeft}>
        {/* <Dropdown
          overlay={() => renderSelectLanguage()}
          trigger={["click"]}
          className={styles.select_language}
        >
          <Button>
            {t("common.language")}
            <DownOutlined />
          </Button>
        </Dropdown> */}
        {!isPageLogin && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Row align="middle">
              <span className={styles.email}>{`${
                localStorage.getItem(ELocalStorageKey.USERNAME)?.split("@")[0]
              }`}</span>
              &nbsp;
              <CaretDownOutlined />
            </Row>
          </Dropdown>
        )}
      </div>
    </div>
  );
});
