import { sendGet, sendPatch, sendPost } from "./axios";
import { Ability, COMMON_STATUS, FeatureManagement } from "utils/enum";
import {
  IParamsListStaffs,
  IResponseListRoles,
  IResponseListStaffs,
} from "utils/interface";

export const getListEmployee = (
  params: IParamsListStaffs
): Promise<IResponseListStaffs> =>
  sendGet("/operation/account/employee", params);

export const getListRoles = (params?: any): Promise<IResponseListRoles> =>
  sendGet("/operation/permission/role", params);

export const getListRolesForDropdown = () =>
  sendGet("/operation/permission/role/create/dropdown");

export const updateStatusEmployer = (
  id: number,
  params: { status: COMMON_STATUS }
) => sendPatch(`/operation/account/employee-status/${id}`, params);

interface IParamsCreate {
  credential: string;
  password?: string;
  code: string;
  name: string;
  email: string;
  phone: string;
  roleId: number;
}
export const createEmployer = (params: IParamsCreate) =>
  sendPost("/operation/account/create-employee", params);

export const updateEmployer = (params: { data: IParamsCreate; id: number }) =>
  sendPatch(`/operation/account/employee/${params.id}`, params.data);

export const updateRolePermission = (params: {
  data: { feature: FeatureManagement; permissions: Ability[] };
  id: number;
}) => sendPatch(`/operation/permission/role/${params.id}`, params.data);

export const getDetailPermission = (id: number) =>
  sendGet(`/operation/permission/role/${id}`);

export const getMyPermissions = () =>
  sendGet("/operation/permission/current-user");
