import AgeAnalysisFilter from "./components/AgeAnalysisFilter";
import styles from "./styles.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { QueryKey } from "utils/enum";
import { useQuery } from "react-query";
import { handleErrorMessage } from "utils/helper";
import { getRangeAgeStatisticApi } from "api/dataAnalysis";
import Loading from "pages/Loading/Loading";
import useFilter from "hooks/useFilter";
import { useTranslation } from "react-i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { position: "bottom" as const },
  },
  scales: {
    y: { title: { display: true, text: "人数" } },
    x: { title: { display: true, text: "年齢" } },
  },
};

export default function AgeAnalysis() {
  const [t] = useTranslation();
  const { filter, handleFilterChange } = useFilter({
    startAt: undefined,
    endAt: undefined,
  });
  const [curData, setCurData] = useState<{
    labels: Array<any>;
    datasets: Array<any>;
  }>({
    labels: [],
    datasets: [],
  });

  const { data, isFetching } = useQuery(
    [QueryKey.STATISTIC_RANGE_AGE, filter],
    () => getRangeAgeStatisticApi(filter),
    { onError: (error) => handleErrorMessage(error) }
  );

  useEffect(() => {
    if (!!data) {
      setCurData({
        labels: data.map((item) => {
          if (item?.target?.includes("Over")) {
            return item?.target?.split("Over")[1] + "歳以上";
          }
          return item?.target;
        }),
        datasets: [
          {
            label: "人数",
            data: data.map((item) => Number(item?.totals)),
            backgroundColor: "#22BBDF",
          },
        ],
      });
    }
  }, [data]);

  return (
    <div className={styles.container}>
      {isFetching && <Loading />}
      <div className={styles.header}>
        <div className={styles.contTitle}>
          <div className={styles.title}>{t("nav.ageAnalysis")}</div>
        </div>
        <AgeAnalysisFilter
          filter={filter}
          handleSearch={(payload: any) => handleFilterChange(payload, true)}
        />
      </div>
      <div style={{ height: 20 }} />
      <div className={styles.content}>
        <Bar options={options} data={curData} />
      </div>
    </div>
  );
}
