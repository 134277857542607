import { getExp } from "api/user";
import { ArrowLeft } from "assets/icon";
import { dataExp } from "constants/fakeData";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { transformArray } from "utils/helper";
import GroupExp from "./component/GroupExp";
import styles from "./styles.module.scss";

export default function ExpUser() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: expUser } = useQuery({
    queryKey: ["expUser"],
    queryFn: () => getExp(id || 0),
    refetchOnWindowFocus: false,
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.back}>
          <ArrowLeft
            onClick={() => {
              navigate(-1);
            }}
          />
          <span>{t("headerPage.userDetail")}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.wrapContainerTitleTable}>
          <div className={styles.wrapColumnOne}>
            <p className={styles.wrapTitleColumn}>{t("expText.date")}</p>
          </div>
          <div className={styles.wrapColumnTwo}>
            <p className={styles.wrapTitleColumn}>{t("expText.storeName")}</p>
          </div>
          <div className={styles.wrapColumnThree}>
            <p className={styles.wrapTitleColumn}>{t("expText.exp")}</p>
          </div>
        </div>
        {expUser?.data?.length === 0 ? (
          <p className={styles.wrapNoHistory}>{t("expText.empty")}</p>
        ) : (
          <div className={styles.wrapListExp}>
            {transformArray(expUser?.data)?.map((item: any, index: number) => {
              return <GroupExp data={item} key={index} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
}
