import React, { Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import AppRoute from "wrappers/AppRoute/AppRoute";
import { Spin } from "antd";
import { AuthProvider } from "hooks/useAuth";
import { createBrowserHistory } from "history";
import "dayjs/locale/ja";
import { PermissionsProvider } from "hooks/usePermissions";
import { handleConnectSocket } from "utils/helper";
import Cookies from "js-cookie";

export const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
    },
  },
});

function App() {
  useEffect(() => {
    if (Cookies.get("token")) {
      handleConnectSocket();
    }
  }, [Cookies.get("token")]);

  return (
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>
        <AuthProvider>
          <Suspense
            fallback={
              <div className="spin">
                <Spin />
              </div>
            }
          >
            <AppRoute />
          </Suspense>
        </AuthProvider>
      </PermissionsProvider>
    </QueryClientProvider>
  );
}

export default App;
