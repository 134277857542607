import { DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";

import styles from "./styles.module.scss";
import { DateIcon } from "assets/icon";
import { IFilter } from "utils/interface";
import CustomButton from "components/CustomButton";
import dayjs from "dayjs";

interface IProps {
  filter: IFilter;
  handleSearch: any;
}

export default function AgeAnalysisFilter({ filter, handleSearch }: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const onSearch = async (payload: any) => {
    handleSearch({
      ...payload,
      startAt: payload?.startAt
        ? dayjs(payload?.startAt).startOf("year")?.toISOString()
        : undefined,
      endAt: payload?.endAt
        ? dayjs(payload?.endAt).endOf("year")?.toISOString()
        : undefined,
    });
  };

  return (
    <Form onFinish={onSearch} form={form}>
      <div className={styles.planFilter}>
        <div className={styles.dateStartFilter}>
          <div className={styles.filterLabel}>{t("labelFilter.startDate")}</div>
          <div className={styles.inputStartDate}>
            <Form.Item name="startAt">
              <DatePicker
                className="datePickerCustom"
                suffixIcon={<Icon component={() => <DateIcon />} />}
                placeholder={t("placeholder.date")}
                picker="year"
                inputReadOnly
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.and}>~</div>
        <div className={styles.dateEndFilter}>
          <div className={styles.filterLabel}>{t("labelFilter.endDate")}</div>
          <div className={styles.inputEndDate}>
            <Form.Item name="endAt">
              <DatePicker
                className="datePickerCustom"
                placeholder={t("placeholder.date")}
                suffixIcon={<Icon component={() => <DateIcon />} />}
                picker="year"
                inputReadOnly
              />
            </Form.Item>
          </div>
        </div>
        <CustomButton
          title={t("button.search")}
          className={styles.searchBtn}
          onClick={() => form.submit()}
        />
      </div>
    </Form>
  );
}
