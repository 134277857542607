import { sendGet, sendPatch } from "./axios";

export const getInfoPopupApi = () =>
  sendGet(`/operation/campaign/popup`).then((res) => res.data);

export const updatePopupPerDayApi = (dataPopup?: any) =>
  sendPatch(`/operation/campaign/multiple-popup`, dataPopup).then(
    (res) => res.data
  );

export const getPopupManager = () =>
  sendGet("/operation/campaign/multiple-popup");

export const getPopupDetail = (id: number) =>
  sendGet(`/operation/campaign/popup/detail/${id}`);
