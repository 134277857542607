import { Modal, Form, Input, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";
import { images } from "assets/iconString";
import styles from "./styles.module.scss";
import CustomButton from "components/CustomButton";
import LabelInput from "components/LabelInput";
import { ArrowBottom } from "assets/icon";
import { useEffect } from "react";
import { commonValidate } from "utils/ruleForms";
import { handleErrorMessage } from "utils/helper";
import { createFAQ, editFAQ } from "api/faq";

interface IProps {
  open: boolean;
  toggle: () => void;
  data?: any;
  onRefetch(): void;
  plansList?: Array<any>;
  modalTitle?: string;
  type: string;
  business?: any;
  isEdit?: boolean;
}

export default function ModalAddFAQ({
  open,
  toggle,
  data,
  onRefetch,
  plansList = [],
  modalTitle = "",
  business,
  type,
  isEdit = false,
}: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = async (payload: any) => {
    try {
      const params = {
        title: payload.title,
        content: payload.content,
        status: payload.status || "shown",
        type: !isEdit ? type : undefined,
      };
      !isEdit && (await createFAQ(params));
      isEdit && (await editFAQ(type, data.id, params));
      onRefetch();
      form.setFieldsValue({
        title: null,
        content: null,
        status: "shown",
      });
      message.success("Success");
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        status: data.status,
        title: data.title,
        content: data.content,
      });
    }
    if (!open) {
      form.resetFields();
    }
  }, [data, open]);

  return (
    <Modal
      title={
        <>
          <div>{modalTitle || t("FAQManagement.addQuestion")}</div>
          <div onClick={toggle}>
            <img src={images.closeModalIcon} />
          </div>
        </>
      }
      className="modalCustom"
      open={open}
      onCancel={toggle}
      closable={false}
      footer={null}
      maskClosable={false}
      centered
      destroyOnClose={true}
    >
      <Form onFinish={handleSubmit} form={form}>
        <div className="f-1 ">
          <LabelInput title={t("FAQManagement.title")} isRequired />
          <Form.Item
            name="title"
            rules={[commonValidate.required, commonValidate.whiteSpace]}
            validateFirst
          >
            <Input.TextArea
              showCount
              maxLength={1000}
              className="textAreaCustom"
              placeholder={t("FAQManagement.placeholderTitle")}
            />
          </Form.Item>
        </div>

        <div className="f-1">
          <LabelInput title={t("FAQManagement.content")} isRequired />
          <Form.Item
            name="content"
            rules={[commonValidate.required, commonValidate.whiteSpace]}
            validateFirst
          >
            <Input.TextArea
              showCount
              maxLength={1000}
              className="textAreaCustom"
              placeholder={t("FAQManagement.placeholderContent")}
            />
          </Form.Item>
        </div>

        <div className="f-1">
          <LabelInput title={t("FAQManagement.status")} isRequired />
          <Form.Item name="status">
            <Select
              defaultValue={"shown"}
              className="selectCustom"
              suffixIcon={<Icon component={() => <ArrowBottom />} />}
              options={[
                { label: t("FAQManagement.active"), value: "shown" },
                { label: t("FAQManagement.inactive"), value: "hidden" },
              ]}
            />
          </Form.Item>
        </div>
        <CustomButton
          title={t("common.approve")}
          onClick={() => form.submit()}
          className={styles.btnSubmit}
        />
      </Form>
    </Modal>
  );
}
