import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { defaultFilterValue } from "constants/defaultValue";
import { IFilter, IFilterQuery, IUseFilterResult } from "utils/interface";
import { COMMON_STATUS, DateOption, DebounceTime } from "utils/enum";
import { convertTimeToUTC, createQueryString } from "utils/helper";
import useQueryParams from "./useQueryParams";

export default function useFilter(
  defaultFilter?: IFilterQuery,
  isSaveInfoFilter?: boolean
): IUseFilterResult {
  const [filter, setFilter] = useState<IFilter>(
    defaultFilter || defaultFilterValue
  );
  const { setQueryObject } = useQueryParams();

  const debounceKeyword = useDebouncedCallback((keyword) => {
    handleFilterChange({
      ...filter,
      keyword,
      page: 1,
    });
  }, DebounceTime.DEFAULT);

  const handleFilterChange = (changeValue: IFilter, resetPageIndex = true) => {
    const newFilter = {
      ...filter,
      ...changeValue,
      page: resetPageIndex ? undefined : 1,
    };
    if (isSaveInfoFilter) {
      setQueryObject(createQueryString(newFilter));
    }
    setFilter(newFilter);
  };

  const keywordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceKeyword(e.target.value);
  };
  const startDateFilter = (value: any) => {
    handleFilterChange({
      startAt: convertTimeToUTC(value, DateOption.START_DATE),
    });
  };

  const endDateFilter = (value: any) => {
    handleFilterChange({
      endAt: convertTimeToUTC(value, DateOption.END_DATE),
    });
  };
  const statusFilter = (value: COMMON_STATUS) => {
    handleFilterChange({
      status: value !== COMMON_STATUS.ALL ? value : undefined,
    });
  };
  const modeFilter = (value: any) => {
    handleFilterChange({
      mode: value,
    });
  };
  const planFilter = (value: COMMON_STATUS) => {
    handleFilterChange({
      plan: value !== COMMON_STATUS.ALL ? value : undefined,
    });
  };
  const handlePageChange = (page: number, pageSize: number) => {
    const newFilter = {
      ...filter,
      page,
      limit: pageSize,
    };
    if (isSaveInfoFilter) {
      setQueryObject(createQueryString(newFilter));
    }
    setFilter(newFilter);
  };
  const resetFilter = () => {
    if (defaultFilter) {
      setFilter({ ...defaultFilter });
    } else {
      setFilter({ ...defaultFilterValue });
    }
  };

  return {
    filter,
    isSaveInfoFilter: !!isSaveInfoFilter,
    handleFilterChange,
    handlePageChange,
    resetFilter,
    handleSearch: {
      keywordSearch,
      startDateFilter,
      endDateFilter,
      statusFilter,
      planFilter,
      modeFilter,
    },
  };
}
