import { Button } from "antd";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { ButtonType } from "utils/enum";

interface IProps {
  title?: string;
  icon?: any;
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  type?: ButtonType;
  disabled?: boolean;
}

export default function CustomButton({
  title,
  icon,
  className,
  onClick,
  type,
  isLoading,
  disabled = false,
}: IProps) {
  return (
    <div
      className={classNames(styles.btnCustom, className, {
        [styles.btnOutline]: type === ButtonType.OUTLINE,
        [styles.btnAction]: type === ButtonType.ACTION,
      })}
    >
      <Button
        icon={icon}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );
}
