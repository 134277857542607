import LoadingComponent from "components/LoadingComponent";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useState } from "react";
import { DatePicker, Form, Input, Radio } from "antd";
import CustomButton from "components/CustomButton";
import { NotiType, QueryKey, SiteType } from "utils/enum";
import { useMutation, useQuery } from "react-query";
import { convertFileTypeFromUrl, handleErrorMessage } from "utils/helper";
import CustomNotification from "components/CustomNotification";
import TextEditor from "components/TextEditor";
import Icon from "@ant-design/icons";

import {
  getInfoPopupApi,
  getPopupDetail,
  updatePopupPerDayApi,
} from "api/popup";
import Loading from "pages/Loading/Loading";
import LabelInput from "components/LabelInput";
import { commonValidate } from "utils/ruleForms";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, DateIcon } from "assets/icon";
import dayjs from "dayjs";
import { ImagesVideosList } from "components/Upload/ImagesVideosList";
import { acceptedImageType, acceptedVideoType } from "utils/const";

export default function DetailPopup() {
  const [t] = useTranslation();
  const [contentPopup, setContentPopup] = useState<string>("");
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const startAt = Form.useWatch("startAt", form);

  const returnFormSite = (site: Array<SiteType>) => {
    if (site?.includes(SiteType.CANDIDATE) && site?.includes(SiteType.EMPLOYER))
      return SiteType.BOTH;
    else if (site?.includes(SiteType.CANDIDATE)) return SiteType.CANDIDATE;
    else return SiteType.EMPLOYER;
  };

  const { data, isLoading: loadingInfoPopup } = useQuery({
    queryKey: [QueryKey.POPUP],
    queryFn: () => getPopupDetail(Number(id)),
    enabled: !!id,
    onSuccess: (res) => {
      form.setFieldsValue({
        title: res?.data?.title,
        content: res?.data?.content,
        sites: returnFormSite(res?.data?.sites),
        startAt: res?.data?.startAt ? dayjs(res?.data?.startAt) : undefined,
        endAt: res?.data?.endAt ? dayjs(res?.data?.endAt) : undefined,
        files: res?.data?.files,
        redirectLink: res?.data?.files?.[0]?.redirectLink,
      });
      setContentPopup(res?.data?.content);
    },
    onError: (error) => {
      handleErrorMessage(error);
    },
  });

  const onChangeContent = (script: string) => {
    form.setFieldsValue({
      content: script,
    });
  };

  const returnSites = (sites: string) => {
    if (sites === SiteType.CANDIDATE) return [SiteType.CANDIDATE];
    else if (sites === SiteType.EMPLOYER) return [SiteType.EMPLOYER];
    return [SiteType.CANDIDATE, SiteType.EMPLOYER];
  };

  const { mutate: handleUpdatePopup, isLoading: loadingUpdatePopup } =
    useMutation(
      (infoPopup: any) =>
        updatePopupPerDayApi([
          {
            title: infoPopup?.title,
            content: infoPopup?.content,
            id: !!id ? Number(id) : undefined,
            startAt: dayjs(form?.getFieldValue("startAt"))
              ?.startOf("day")
              ?.toISOString(),
            endAt: dayjs(form?.getFieldValue("endAt"))
              ?.endOf("day")
              ?.toISOString(),
            sites: returnSites(form?.getFieldValue("sites")),
          },
        ]),

      {
        onSuccess: () => {
          !id && navigate("/master-data/popup-manager");
          CustomNotification({
            type: NotiType.SUCCESS,
            message: t("notification.success"),
          });
        },
        onError: (error) => handleErrorMessage(error),
      }
    );

  return (
    <div className={styles.container}>
      {loadingUpdatePopup && <Loading />}
      <div className={styles.header}>
        <ArrowLeft
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className={styles.title}>{t("popup.detailPopup")}</span>
      </div>
      {loadingInfoPopup ? (
        <LoadingComponent />
      ) : (
        <div className={styles.body}>
          <Form
            onFinish={handleUpdatePopup}
            form={form}
            name="dynamic_form_complex"
            layout="vertical"
          >
            <div className={styles.detailLastRow}>
              {data?.data?.title && (
                <>
                  <LabelInput title={t("popup.titlePopup")} />
                  <Form.Item
                    name="title"
                    rules={[commonValidate.required, commonValidate.whiteSpace]}
                  >
                    <Input
                      className="title"
                      placeholder={t("popup.titlePopup")}
                      disabled
                    />
                  </Form.Item>
                </>
              )}

              {data?.data?.files && (
                <>
                  <LabelInput title={t("popup.uploadFile")} />
                  <div className={styles.profileContainer}>
                    <ImagesVideosList
                      isDeletable={false}
                      data={data?.data?.files?.map((item: any) => {
                        return {
                          url: item?.url,
                          type:
                            item?.type ||
                            convertFileTypeFromUrl(item?.url) ||
                            "",
                          name: item?.path || "",
                        };
                      })}
                      isWrapped={true}
                      acceptedFilesTypeArr={[
                        ...acceptedImageType,
                        ...acceptedVideoType,
                      ]}
                      itemSizeWidth={48}
                      itemSizeHeight={64.656}
                    />
                  </div>
                </>
              )}
            </div>

            {data?.data?.files?.[0]?.redirectLink && (
              <>
                <LabelInput title={t("popup.redirectLink")} />
                <Form.Item name="redirectLink">
                  <Input
                    className="title"
                    placeholder={t("popup.redirectLink")}
                    disabled
                  />
                </Form.Item>
              </>
            )}

            {data?.data?.content && (
              <>
                <LabelInput title={t("popup.contentPopup")} />
                <Form.Item
                  name="content"
                  rules={[commonValidate.required, commonValidate.whiteSpace]}
                >
                  <TextEditor
                    onChangeText={onChangeContent}
                    data={contentPopup}
                    disabled
                  />
                </Form.Item>
              </>
            )}

            <LabelInput title={t("table.startAt")} isRequired />
            <Form.Item
              name="startAt"
              rules={[commonValidate.required]}
              dependencies={["endAt"]}
            >
              <DatePicker
                className="datePickerCustom"
                suffixIcon={<Icon component={() => <DateIcon />} />}
                placeholder={t("placeholder.date")}
                inputReadOnly
                disabledDate={(value: any) =>
                  dayjs(value)?.isBefore(dayjs(), "date")
                }
                disabled
              />
            </Form.Item>

            <LabelInput title={t("table.endAt")} isRequired />
            <Form.Item
              name="endAt"
              rules={[
                commonValidate.required,
                commonValidate.CheckStartAt(startAt),
              ]}
              dependencies={["startAt"]}
            >
              <DatePicker
                className="datePickerCustom"
                suffixIcon={<Icon component={() => <DateIcon />} />}
                placeholder={t("placeholder.date")}
                inputReadOnly
                disabledDate={(value: any) =>
                  dayjs(value)?.isBefore(dayjs(), "date")
                }
                disabled
              />
            </Form.Item>

            <LabelInput title={t("table.sites")} isRequired />
            <Form.Item name={"sites"} rules={[commonValidate.required]}>
              <Radio.Group
                className="radioCustom"
                defaultValue={false}
                disabled
              >
                <Radio value={SiteType.CANDIDATE}>{t("popup.candidate")}</Radio>

                <Radio value={SiteType.EMPLOYER}>{t("popup.employer")}</Radio>

                <Radio value={SiteType.BOTH}>{t("popup.both")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}
