import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import CustomButton from "components/CustomButton";
// import TableStore from "./components/TableStore";
import styles from "./styles.module.scss";
import { AddIcon } from "assets/icon";
import PaginationCustom from "components/PaginationCustom";
import { FeatureManagement, QueryKey } from "utils/enum";
import useFilterAPI from "hooks/useFilterAPI";
import { getUserList } from "api/user";
import { statusFilterUser } from "utils/const";
import { usePermissions, useRole } from "hooks/usePermissions";
import { hasCreatePermission } from "utils/helper";
import useGetFilterQuery from "hooks/useGetFilterQuery";
import { useEffect, useState } from "react";
import socket from "api/socket";
import HistoryFilter from "./components/HistoryFilter";
import HistoryTable from "./components/HistoryTable";
import { getListHistory } from "api/post";
import useFilter from "hooks/useFilter";

export default function ApplicationHistory() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useRole();
  const [listStoreActive, setListStoreActive] = useState<any[]>([]);

  const { filter, handleSearch, handleFilterChange, handlePageChange } = useFilter(
    useGetFilterQuery({
      defaultFilter: {
        page: 1,
        limit: 100,
      },
    }),
    true
  );

  const { data: listUserManage, isLoading: loadingListListPost } = useQuery(
    [QueryKey.LIST_USER, filter],
    () =>
      getListHistory({
        ...filter,
      }),
    {
      onSuccess: (data) => {
        setListStoreActive(
          data?.data?.items
            ?.filter((item: any) => item?.accountOnline?.isOnline)
            ?.map((item: any) => item?.accountOnline)
        );
      },
    }
  );

  useEffect(() => {
    socket.on("account_online_offline", (data: any) => {
      const listActive = !listStoreActive
        ?.map((item: any) => item?.accountId)
        ?.includes(data?.accountId)
        ? [...(!!listStoreActive ? listStoreActive : []), data]
        : listStoreActive?.map((item: any) => {
            if (item?.accountId === item?.accountId) return data;
            return item;
          });
      setListStoreActive(listActive);
    });
    return () => {
      socket.off("account_online_offline");
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.userHeader}>
        <div className={styles.topHeader}>
          <div className={styles.title}>{t("application.header")}</div>
        </div>
        <HistoryFilter
          filter={filter}
          handleSearch={handleSearch}
          dataSelect={statusFilterUser}
          onSearch={handleFilterChange}
        />
      </div>
      <div className={styles.storeTable}>
        <HistoryTable
          data={listUserManage?.data?.items || []}
          filter={filter}
          loading={false}
          listStoreActive={listStoreActive}
        />
        <PaginationCustom
          pageIndex={filter.page || 1}
          pageSize={filter.limit || 10}
          onPageChange={handlePageChange}
          totalItems={listUserManage?.data?.totalItems || 0}
          className="mt-20"
        />
      </div>
    </div>
  );
}
