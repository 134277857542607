import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

interface IProps {
  date: string;
  content: string;
  exp: number;
  idStore: number;
}

export default function ItemExp({ date, content, exp, idStore }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.wrapContainerTitle}>
      <p className={styles.wrapColumnOne}>{date}</p>
      <div
        className={styles.wrapColumnTwo}
        onClick={() => navigate(`/store-account/store-list/detail/${idStore}`)}
      >
        {content}
      </div>
      <p
        className={classNames(
          styles.wrapColumnThreeTable,
          exp > 0 ? styles.colorBlack : styles.colorRed
        )}
      >
        <span
          className={classNames(
            styles.wrapColumnThreeExp,
            exp > 0 ? styles.colorBlack : styles.colorRed
          )}
        >
          {exp > 0 ? "+" : ""}
        </span>
        {exp}
        <span
          className={classNames(styles.wrapColumnThreeExp, styles.colorBlack)}
        >
          {t("expText.exp")}
        </span>
      </p>
    </div>
  );
}
