import LoadingComponent from "components/LoadingComponent";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { DatePicker, Form, Input, Radio } from "antd";
import CustomButton from "components/CustomButton";
import { NotiType, popupType, QueryKey, SiteType } from "utils/enum";
import { useMutation, useQuery } from "react-query";
import {
  convertFileTypeFromUrl,
  handleErrorMessage,
  handlePreSignListFiles,
  returnAspect,
} from "utils/helper";
import CustomNotification from "components/CustomNotification";
import TextEditor from "components/TextEditor";
import Icon from "@ant-design/icons";

import {
  getInfoPopupApi,
  getPopupDetail,
  updatePopupPerDayApi,
} from "api/popup";
import Loading from "pages/Loading/Loading";
import LabelInput from "components/LabelInput";
import { commonValidate } from "utils/ruleForms";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, DateIcon } from "assets/icon";
import dayjs from "dayjs";
import {
  acceptedDocumentsType,
  acceptedImageType,
  acceptedVideoType,
} from "utils/const";
import ImageUploader from "components/Upload/ImageUploader";
import i18next from "i18next";

export default function EditPopup() {
  const [t] = useTranslation();
  const [contentPopup, setContentPopup] = useState<string>("");
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const startAt = Form.useWatch("startAt", form);
  const type = Form.useWatch("type", form);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [loadingUpdatePopup, setLoadingUpdatePopup] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ type: popupType.IMAGE });
  }, []);
  const [generalErrorText, setGeneralErrorText] = useState<{
    files?: string;
    registrationPermitFiles?: string;
  }>({
    files: "",
    registrationPermitFiles: "",
  });

  const returnFormSite = (site: Array<SiteType>) => {
    if (site?.includes(SiteType.CANDIDATE) && site?.includes(SiteType.EMPLOYER))
      return SiteType.BOTH;
    else if (site?.includes(SiteType.CANDIDATE)) return SiteType.CANDIDATE;
    else return SiteType.EMPLOYER;
  };

  const { isLoading: loadingInfoPopup } = useQuery({
    queryKey: [QueryKey.POPUP],
    queryFn: () => getPopupDetail(Number(id)),
    enabled: !!id,
    onSuccess: (res) => {
      form.setFieldsValue({
        type: res?.data?.files?.[0]?.type?.includes("video")
          ? popupType.VIDEO
          : popupType.IMAGE,
        // title: res?.data?.title,
        // content: res?.data?.content || "",
        sites: returnFormSite(res?.data?.sites),
        startAt: res?.data?.startAt ? dayjs(res?.data?.startAt) : undefined,
        endAt: res?.data?.endAt ? dayjs(res?.data?.endAt) : undefined,
        files: res?.data?.files,
        redirectLink: res?.data?.files?.[0]?.type?.includes("image")
          ? res?.data?.files?.[0]?.redirectLink
          : undefined,
        thumbnails: res?.data?.files?.[0]?.type?.includes("video")
          ? res?.data?.thumbnails
          : undefined,
      });
      setContentPopup(res?.data?.content || "");
    },
    onError: (error) => {
      handleErrorMessage(error);
    },
  });

  const onChangeContent = (script: string) => {
    form.setFieldsValue({
      content: script,
    });
  };

  const returnSites = (sites: string) => {
    if (sites === SiteType.CANDIDATE) return [SiteType.CANDIDATE];
    else if (sites === SiteType.EMPLOYER) return [SiteType.EMPLOYER];
    return [SiteType.CANDIDATE, SiteType.EMPLOYER];
  };

  // const { mutate: handleUpdatePopup, isLoading: loadingUpdatePopup } =
  //   useMutation(
  //     (infoPopup: any) =>
  //       updatePopupPerDayApi([
  //         {
  //           title: infoPopup?.title,
  //           content: infoPopup?.content,
  //           id: !!id ? Number(id) : undefined,
  //           startAt: dayjs(form?.getFieldValue("startAt"))
  //             ?.startOf("day")
  //             ?.toISOString(),
  //           endAt: dayjs(form?.getFieldValue("endAt"))
  //             ?.endOf("day")
  //             ?.toISOString(),
  //           sites: returnSites(form?.getFieldValue("sites")),
  //         },
  //       ]),

  //     {
  //       onSuccess: () => {
  //         navigate("/master-data/popup-manager");
  //         CustomNotification({
  //           type: NotiType.SUCCESS,
  //           message: t("notification.success"),
  //         });
  //       },
  //       onError: (error) => handleErrorMessage(error),
  //     }
  //   );

  const handleSubmit = async (infoPopup: any) => {
    try {
      setLoadingUpdatePopup(true);
      const filesToUpload = await handlePreSignListFiles({
        keyString: "files",
        payload: infoPopup,
      });
      const thumbnailToUpload = await handlePreSignListFiles({
        keyString: "thumbnails",
        payload: infoPopup,
      });
      await updatePopupPerDayApi([
        {
          //   title: infoPopup?.title,
          //   content: infoPopup?.content,
          id: !!id ? Number(id) : undefined,
          startAt: dayjs(form?.getFieldValue("startAt"))
            ?.startOf("day")
            ?.toISOString(),
          endAt: dayjs(form?.getFieldValue("endAt"))
            ?.endOf("day")
            ?.toISOString(),
          sites: returnSites(form?.getFieldValue("sites")),
          files: filesToUpload?.map((item: any) => {
            return {
              ...item,
              redirectLink: !!infoPopup?.redirectLink
                ? infoPopup?.redirectLink
                : undefined,
            };
          }),
          redirectLink: undefined,
          thumbnails:
            thumbnailToUpload && filesToUpload?.[0]?.type?.includes("video")
              ? thumbnailToUpload
              : undefined,
        },
      ]);
      setLoadingUpdatePopup(false);
      navigate("/master-data/popup-manager");
      CustomNotification({
        type: NotiType.SUCCESS,
        message: t("notification.success"),
      });
    } catch (err: any) {
      setLoadingUpdatePopup(false);
      handleErrorMessage(err);
    }
  };

  const renderListFilesUploader = (
    keyString: string,
    typePopup?: popupType
  ) => {
    let acceptTypes = [...acceptedImageType, ...acceptedVideoType];
    if (keyString === "registrationPermitFiles") {
      acceptTypes = [...acceptedDocumentsType, ...acceptedImageType];
    }
    if (keyString === "thumbnails") {
      acceptTypes = [...acceptedImageType];
    }
    if (typePopup === popupType.IMAGE) {
      acceptTypes = [...acceptedImageType];
    }
    if (typePopup === popupType.VIDEO) {
      acceptTypes = [...acceptedVideoType];
    }
    return (
      <Form.Item name={keyString}>
        <ImageUploader
          filesListFromProps={form
            ?.getFieldValue(keyString)
            ?.map((item: any) => {
              if (item?.id) {
                return {
                  ...item,
                  file: {
                    name: item?.file?.name,
                    type:
                      item?.type ||
                      convertFileTypeFromUrl(item?.url) ||
                      item?.file?.type,
                    size: item?.file?.size,
                  },
                };
              }
              return item;
            })}
          type="uploadPhoto"
          limitMaxImage={1}
          onFileChange={(data: any) => {
            const dataFile = data?.files || data;
            // if (dataFile?.length > 0) {
            form.setFieldsValue({ [keyString]: dataFile });
            const errorTextsWithValue = dataFile?.filter(
              (fileItem: any) => !!fileItem?.errorText
            );
            setGeneralErrorText({
              ...generalErrorText,
              [keyString]:
                !errorTextsWithValue || errorTextsWithValue?.length === 0
                  ? ""
                  : i18next.t("errorText.generalUploadImageVideoDanger"),
            });
            // }
          }}
          acceptedFilesTypeArr={acceptTypes}
          defaultText={t("addUserText.fileSelection")}
          itemSizeWidth={48}
          itemSizeHeight={64.656}
          hasCrop={false}
          // hasCrop={keyString==="thumbnails"?true: false} crop ảnh bang theo ti le video
          // setVideoDimensions={setVideoDimensions}  crop ảnh bang theo ti le video
          // aspect={
          //   keyString === "thumbnails"
          //     ? returnAspect(videoDimensions?.width, videoDimensions?.height)
          //     : "1:1"
          // }
        />
        {/* <div className={styles.videoDes}>{t("addUserText.noteImage")}</div> */}
        <div>
          {(generalErrorText as any)?.[keyString] && (
            <div className={styles.txtError}>
              {(generalErrorText as any)?.[keyString]}
            </div>
          )}
        </div>
      </Form.Item>
    );
  };

  return (
    <div className={styles.container}>
      {loadingUpdatePopup && <Loading />}
      <div className={styles.header}>
        <ArrowLeft
          onClick={() => {
            navigate(-1);
          }}
        />

        <span className={styles.title}>{t("popup.detailPopup")}</span>
      </div>
      {loadingInfoPopup ? (
        <LoadingComponent />
      ) : (
        <div className={styles.body}>
          <Form
            onFinish={handleSubmit}
            form={form}
            name="dynamic_form_complex"
            layout="vertical"
          >
            <LabelInput title={t("popup.type")} />
            <Form.Item name="type">
              <Radio.Group
                className="radioCustom"
                defaultValue={popupType.IMAGE}
              >
                <Radio value={popupType.IMAGE} style={{ flex: 1 }}>
                  {t("popup.image")}
                </Radio>

                <Radio value={popupType.VIDEO} style={{ flex: 2 }}>
                  {t("popup.video")}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <LabelInput title={t("popup.uploadFile")} />
            {renderListFilesUploader("files", type)}

            {type === popupType.IMAGE ? (
              <>
                {" "}
                <LabelInput title={t("popup.redirectLink")} />
                <Form.Item
                  name="redirectLink"
                  rules={[commonValidate.whiteSpace, commonValidate.url]}
                >
                  <Input
                    className="title"
                    placeholder={t("popup.redirectLink")}
                    maxLength={255}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <LabelInput title={t("popup.thumbnail")} />
                {renderListFilesUploader("thumbnails")}
              </>
            )}

            {/* <LabelInput title={t("popup.contentPopup")} />
            <Form.Item name="content" rules={[commonValidate.whiteSpace]}>
              <TextEditor onChangeText={onChangeContent} data={contentPopup} />
            </Form.Item> */}

            <LabelInput title={t("table.startAt")} isRequired />
            <Form.Item
              name="startAt"
              rules={[commonValidate.required]}
              dependencies={["endAt"]}
            >
              <DatePicker
                className="datePickerCustom"
                suffixIcon={<Icon component={() => <DateIcon />} />}
                placeholder={t("placeholder.date")}
                inputReadOnly
                disabledDate={(value: any) =>
                  dayjs(value)?.isBefore(dayjs(), "date")
                }
              />
            </Form.Item>

            <LabelInput title={t("table.endAt")} isRequired />
            <Form.Item
              name="endAt"
              rules={[
                commonValidate.required,
                commonValidate.CheckStartAt(startAt),
              ]}
              dependencies={["startAt"]}
            >
              <DatePicker
                className="datePickerCustom"
                suffixIcon={<Icon component={() => <DateIcon />} />}
                placeholder={t("placeholder.date")}
                inputReadOnly
                disabledDate={(value: any) =>
                  dayjs(value)?.isBefore(dayjs(), "date")
                }
              />
            </Form.Item>

            <LabelInput title={t("table.sites")} isRequired />
            <Form.Item name={"sites"} rules={[commonValidate.required]}>
              <Radio.Group className="radioCustom" defaultValue={false}>
                <Radio value={SiteType.CANDIDATE} style={{ flex: 1 }}>
                  {t("popup.candidate")}
                </Radio>

                <Radio value={SiteType.EMPLOYER} style={{ flex: 1 }}>
                  {t("popup.employer")}
                </Radio>

                <Radio value={SiteType.BOTH} style={{ flex: 1 }}>
                  {t("popup.both")}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <CustomButton
              title={t("button.send")}
              className={styles.sendBtn}
              onClick={() => form.submit()}
              isLoading={loadingUpdatePopup}
            />
          </Form>
        </div>
      )}
    </div>
  );
}
