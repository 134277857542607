import { Checkbox, Form } from "antd";
import LabelInput from "components/LabelInput";
import React, { ReactNode } from "react";
import { Ability } from "utils/enum";
import styles from "./styles.module.scss";

interface IProps {
  title: string | undefined;
  children: ReactNode;
  name: string;
}

function RoleItem({ title = "", children, name }: IProps) {
  return (
    <div className={styles.row}>
      <div className="f-1">
        <LabelInput title={title} />
        <Form.Item name={name}>
          <Checkbox.Group>{children}</Checkbox.Group>
        </Form.Item>
      </div>
    </div>
  );
}

export default RoleItem;
