import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Delete, Detail, Edit } from "components/ActionTable/ActionTable";
import dayjs from "dayjs";
import Relative from "dayjs/plugin/relativeTime";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FAQStatus } from "utils/enum";
import { getIndexTable } from "utils/helper";
import { IContact, IFilter } from "utils/interface";
import ModalAddFAQ from "../Modal/ModalAddFAQ";
import ModalDetailFAQ from "../Modal/ModalDetailFAQ";
import styles from "./styles.module.scss";

interface IProps {
  data: IContact[];
  filter: IFilter;
  loading: boolean;
  onDelete(id: number): void;
  setOpenEdit(data: boolean): void;
  openEdit: boolean;
  site: string;
  onRefreshList(): void;
}

dayjs.extend(Relative);

export default function FAQTable({
  data,
  filter,
  loading,
  onDelete,
  setOpenEdit,
  openEdit,
  site,
  onRefreshList,
}: IProps) {
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);
  const ref: any = useRef();

  const translatePostStatus = useCallback((contactStatus: string) => {
    const data: any = {
      background: "",
      text: "",
    };
    switch (contactStatus) {
      case FAQStatus.HIDDEN:
        data.background = "#F971A5";
        data.text = t("FAQManagement.inactive");
        break;
      case FAQStatus.SHOWN:
        data.background = "#87D6EA";
        data.text = t("FAQManagement.active");
        break;

      default:
        break;
    }
    return data;
  }, []);

  const columns: ColumnsType<IContact> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
      width: 80,
    },
    {
      title: t("FAQManagement.title"),
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => (
        <span className={styles.title}>
          {record?.title?.length > 22
            ? `${record?.title?.slice(0, 22)}...`
            : record?.title}
        </span>
      ),
      width: 250,
    },
    {
      title: t("FAQManagement.content"),
      dataIndex: "storeName",
      key: "storeName",
      render: (value, record, index) => (
        <div className={styles.content}>
          <span>{record?.content}</span>
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (value, record, index) => (
        <span
          className={styles.labelStatus}
          style={{
            background: translatePostStatus(record?.status)?.background,
          }}
        >
          {translatePostStatus(record?.status)?.text}
        </span>
      ),
    },
    {
      title: t("FAQManagement.operate"),
      key: "action",
      width: 150,
      render: (value, record, index) => (
        <div className={styles.center}>
          <Detail
            onClick={() => {
              ref.current = value;
              setOpenDetail(true);
            }}
          />
          <Edit
            onClick={() => {
              ref.current = value;
              setOpenEdit(true);
            }}
          />
          <Delete
            onDelete={() => {
              onDelete && onDelete(record?.id);
            }}
            textConfirm={"質問を削除してもよろしいでしょうか。"}
          />
        </div>
      ),
      align: "center",
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.id}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
      />
      <ModalAddFAQ
        open={openEdit}
        toggle={() => setOpenEdit(false)}
        onRefetch={onRefreshList}
        type={site}
        data={ref.current}
        isEdit={true}
      />
      <ModalDetailFAQ
        open={openDetail}
        toggle={() => setOpenDetail(false)}
        title={ref.current?.title}
        content={ref.current?.content}
      />
    </>
  );
}
