import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import styles from "./styles.module.scss";

interface IProps {
  onChangeText: (data: string) => void;
  disabled?: boolean;
  data?: string;
}

export default function TextEditor({
  onChangeText,
  disabled,
  data = "",
}: IProps) {
  const configEditor = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "link",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "alignment",
      "blockQuote",
    ],
  };
  return (
    <div className={styles.textEditor}>
      <CKEditor
        editor={Editor}
        data={data}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          onChangeText(data);
        }}
        config={configEditor}
        disabled={disabled}
      />
    </div>
  );
}
