import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getTreatmentHistoryStatistic } from "api/dataAnalysis";
import PaginationCustom from "components/PaginationCustom";
import useFilterAPI from "hooks/useFilterAPI";
import { QueryKey } from "utils/enum";
import { handleErrorMessage } from "utils/helper";
import AgeAnalysisFilter from "./components/TreatmentSearchFilter";
import PostTable from "./components/TreatmentSearchTable";
import styles from "./styles.module.scss";

export default function TreatmentSearch() {
  const [t] = useTranslation();

  const [date, setDate] = useState<{
    startAt: string | null;
    endAt: string | null;
  }>({
    endAt: null,
    startAt: null,
  });

  const { filter, handlePageChange } = useFilterAPI({
    page: 1,
    limit: 10,
  });
  const handleFilterChange = (payload: { startAt: any; endAt: any }) => {
    setDate({
      startAt: payload?.startAt
        ? dayjs(payload.startAt).startOf("day").toISOString()
        : null,
      endAt: payload?.endAt
        ? dayjs(payload.endAt).endOf("day").toISOString()
        : null,
    });
  };

  const { data: listPostManage, isFetching: loadingListBug } = useQuery(
    [QueryKey.STATISTIC_HISTORY_SEARCH, filter, date],
    () =>
      getTreatmentHistoryStatistic({
        startAt: date.startAt,
        endAt: date.endAt,
        page: filter?.page || 1,
        limit: 10,
      }),
    { onError: (error) => handleErrorMessage(error) }
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.postHeader}>
          <div className={styles.title}>{t("nav.treatmentSearch")}</div>
        </div>
        <AgeAnalysisFilter
          filter={filter}
          handleSearch={(payload: any) => handleFilterChange(payload)}
        />
      </div>
      <div className={styles.storeTable}>
        <div className={styles.tableContainer}>
          <PostTable
            data={listPostManage?.data?.items || []}
            filter={filter}
            loading={loadingListBug}
          />
          <PaginationCustom
            pageIndex={filter.page || 1}
            pageSize={filter.limit || 10}
            onPageChange={handlePageChange}
            totalItems={listPostManage?.data?.totalItem || 0}
            className={classNames(styles.pagination)}
          />
        </div>
      </div>
    </div>
  );
}
