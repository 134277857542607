import { message, Tabs, TabsProps } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";

import PaginationCustom from "components/PaginationCustom";
import useFilterAPI from "hooks/useFilterAPI";
import { statusFilterFAQ } from "utils/const";
import { FAQType } from "utils/enum";
import { handleErrorMessage } from "utils/helper";
import styles from "./styles.module.scss";
import FAQFilter from "./components/FAQFilter";
import FAQTable from "./components/FAQTable";
import { deleteFAQ, getFAQList } from "api/faq";
import CustomButton from "components/CustomButton";
import { AddIcon } from "assets/icon";
import Icon from "@ant-design/icons";
import ModalAddFAQ from "./components/Modal/ModalAddFAQ";
import useFilter from "hooks/useFilter";

export default function FAQManage() {
  const [t] = useTranslation();
  const queryClient = useQueryClient();

  const [site, setSite] = useState<FAQType>(FAQType.USER);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const items: TabsProps["items"] = [
    {
      key: FAQType.USER,
      label: t("FAQManagement.userSite"),
    },
    {
      key: FAQType.STORE,
      label: t("FAQManagement.storeSite"),
    },
  ];

  const { filter, handlePageChange, handleFilterChange, resetFilter } =
    useFilter({
      page: 1,
      limit: 10,
    });

  const { data: listFAQManage, isFetching: loadingListBug } = useQuery(
    ["listFAQManage", filter, site],
    () =>
      getFAQList(site, {
        ...filter,
        status: filter.status,
      }),
    { onError: (error) => handleErrorMessage(error) }
  );

  const handleOnChangeTab = (activeKey: any) => {
    resetFilter();
    setSite(activeKey);
  };

  const onRefreshList = async () => {
    try {
      setOpenAdd(false);
      setOpenEdit(false);
      await queryClient.invalidateQueries(["listFAQManage"]);
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const onDelete = async (id: number) => {
    try {
      await deleteFAQ(id);
      message.success("Delete Success");
      onRefreshList();
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.postHeader}>
          <div className={styles.title}>{t("FAQManagement.FAQTitle")}</div>
          <CustomButton
            title={t("FAQManagement.addFAQ")}
            icon={<Icon component={() => <AddIcon />} />}
            className={styles.addStoreBtn}
            onClick={() => setOpenAdd(true)}
          />
        </div>
        <FAQFilter dataSelect={statusFilterFAQ} onSearch={handleFilterChange} />
      </div>
      <div className={styles.storeTable}>
        <Tabs
          activeKey={site}
          items={items}
          onChange={handleOnChangeTab}
          className={styles.tabs}
        />
        <div className={styles.tableContainer}>
          <FAQTable
            data={listFAQManage?.data?.items || []}
            filter={filter}
            loading={loadingListBug}
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            site={site}
            onRefreshList={onRefreshList}
            onDelete={onDelete}
          />
          <PaginationCustom
            pageIndex={filter.page || 1}
            pageSize={filter.limit || 10}
            onPageChange={handlePageChange}
            totalItems={listFAQManage?.data?.totalItems || 0}
            className={classNames(styles.pagination)}
          />
        </div>
      </div>
      <ModalAddFAQ
        open={openAdd}
        toggle={() => setOpenAdd(false)}
        onRefetch={onRefreshList}
        type={site}
      />
    </div>
  );
}
